.mobileSectionOn {
  display: none;
}

@media (min-width: 1080px) {
  /* #Ingredient_area .ingredient_hover2 {
    margin-left: -20px;
  } */

  /* .to_big_why_content {
    padding-right: 12rem;
    width: auto;
    margin-top: 20px;
    height: 40rem;
  } */
  /* .to_big_why_content_cat_name {
    height: 60rem;
  } */
  /* .to_big_why_content_cat_name h2 {
    font-size: 57px;
    margin-top: 2rem;
    text-align: start;
  } */
  .ctaExample {
    text-align: left;
    padding: 10px 3rem;
  }
}
@media (min-width: 1080px) {
  .bannerColHeightSet{
    min-height: 320px;
    /* background-color: green; */
    min-width: 50px;

  }
}
@media (max-width: 1440px) {
  .ingredient_hover1 img,
  .ingredient_hover3 img,
  .ingredient_hover6 img,
  .ingredient_hover1 img,
  .ingredient_hover9 img,
  .ingredient_hover1 img,
  .ingredient_hover2 {
    height: 300px;
    width: 100%;
  }
  .BigWhy_ImageCarosalContainer{
    height: 668px;
  }
  .blogimgBottum img {
    width: 100%;
    height: 17rem;
  }
  .blogImgtopRight1 {
    height: 22.45rem;
    width: 100%;
  }
  .footer_left_side {
    text-align: center;
  }
 

  .product_var_one_text_center {
    left: 30px;
    -moz-transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    width: 80%;
  }
  .Collagen_peptides {
    min-height: 35rem;
    margin-top: -80%;
  }

  .main-menu nav > ul > li > a {
    padding: 5px 10px;
  }

  .mobile-menu-logo .logo {
    width: 65%;
    overflow: hidden;
  }

  .cookie-bar p {
    font-size: 14px;
  }

  .drodo-nav .navbar .navbar-nav .nav-item a {
    margin-left: 20px;
    margin-right: 20px;
  }

}

@media (max-width: 1199px) {
  .offside-menu {
    background: transparent;
  }
  .offside-menu i {
    color: #000;
  }
  .Collagen_peptides {
    margin-top: -110%;
  }
  #to_big_why_row {
    margin-top: -10rem;
  }
  .mobileSectionOn {
    display: none !important;
  }
  /* home section */
  .blog_list_first_col .blog_title {
    height: 15.4rem;
  }
  .Collagen_peptides {
    min-height: 35rem;
    margin-top: -80%;
  }
  .blogimgBottum img {
    height: 14.72rem;
  }
  .blogImgtopRight1 {
    height: 22.45rem;
    width: 100%;
  }
  .BigWhy_ImageCarosalContainer{
    height: 514px;
  }
  .ingredient_hover1 img,
  .ingredient_hover3 img,
  .ingredient_hover6 img,
  .ingredient_hover1 img,
  .ingredient_hover9 img,
  .ingredient_hover1 img,
  .ingredient_hover2,
  .ingredient_hover4,
  .ingredient_hover5,
  .ingredient_hover10,
  .ingredient_hover12,
  .ingredient_hover7 {
    height: 400px;
    width: 100%;
  }

  .MobileMenuList {
    color: #fff;
  }
  /* packaging section */
  .packag_img_flex img {
    width: 50%;
  }
  /* div#to_big_why_row {
    top: 20%;
    right: 5%;
  } */

  /* .to_big_why_content {
    padding: 20px;
  } */
  .to_big_why_content h2 {
    font-size: 30px;
  }


  .banner_text_one h1 {
    font-size: 115px;
    line-height: 110px;
  }

  .btn_media_viewmore a {
    padding-left: 10px;
    padding-right: 10px;
  }
  #Ingredient_area .ingredient_box {
    padding: 10px 15px;
    overflow: hidden;
  }
  #Ingredient_area .ingredient_box p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .tables_area {
    width: 625px;
  }
  .furniture_slider {
    margin-top: 2rem;
  }
  #to_big_why_row {
    margin-top: -21rem;
  }
  .usefullLinks {
    text-align: center;
  }
  .ingredient_hover1 img,
  .ingredient_hover2 {
    /* width: 350px; */
  }
  .BigWhy_ImageCarosalContainer{
    height: 438px;
  }
  /* uniquenss of our formula section */
  .about_top_img {
    width: 100%;
    margin: auto;
  }
  #footer_one,
  #copyright_one {
    font-size: 12px;
  }
  #cart_area_three .coupon_code {
    margin-top: 30px;
  }

  
  .blogimgBottum img {
    height: auto;
  }

  .jewel_serv_card {
    padding-bottom: 30px;
  }

  .theme-invoice-4 .invoice-wrapper .invoice-footer ul li h4 {
    line-height: 24px;
    font-size: 14px;
  }

  .theme-invoice-4 .invoice-wrapper .invoice-footer ul li i {
    margin-right: 7px;
    font-size: 16px;
  }

  .furniture_bottom_wrapper {
    margin-bottom: 30px;
  }

  #shop_main_area
    .row:nth-child(2)
    .col-lg-12:nth-child(2)
    .product_wrappers_list {
    margin-top: 30px;
  }

  .product_wrappers_list .content .product_para_top {
    padding-top: 20px;
  }

  .modal_product_content_one {
    padding-top: 20px;
  }

  .product_wrappers_list {
    padding: 20px 20px;
  }

  .footer_one_widget {
    padding-top: 25px;
  }



  #product_variation_one .container-fluid .row .col-lg-4:last-child {
    display: none;
  }

  .banner_text_one a {
    margin-top: 20px;
  }

  .product_shot {
    justify-content: flex-start;
  }

  .product_filter {
    padding-bottom: 25px;
  }

  #related_product .product_wrappers_one {
    padding-bottom: 30px;
  }

  .about_top_left_content {
    padding-top: 30px;
  }

  .service_promo_single_item {
    height: auto;
    margin-bottom: 30px;
  }

  .account_setting {
    padding-top: 30px;
    padding-left: 0;
  }

  #furniture_story {
    padding: 100px 0;
  }

  .right_block {
    position: inherit;
    width: 100%;
    background: #fff;
    padding: 30px 0 0 0;
  }

  .right_side_content p {
    color: var(--paragraph-color);
  }

  .right_side_content h5 {
    color: var(--heading-color);
  }

  .right_side_content a {
    color: var(--heading-color);
  }

  .vendor_top_box {
    margin-top: 30px;
  }

  

  .banner_last_img {
    padding-top: 20px;
  }

  .lowerTextClinicalStudies {
    margin-left: 15rem;
  }
  .discount_thumb img {
    width: 100%;
    margin-bottom: 30px;
  }

  /* media section */
  .btn_media_viewmore a {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  /* #medialist_area .listTabeMedia {
    margin-left: 55px;
  }
  #medialist_area .listTabeMediaRight {
    margin-right: 140px;
  } */
  #aboutClinicalStudy .imageHolderClinical::after {
    bottom: 15rem;
    left: -5rem;
  }
  #aboutClinicalStudy .imageHolderClinical::before {
    top: -21rem;
    left: -14rem;
  }
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 6rem;
  }
}

@media (max-width: 767px) {
  .tables_area {
    width: 425px;
  }
  .BigWhy_ImageCarosalContainer{
    height: 335px;
  }
  #to_big_why_row {
    margin-top: -15rem;
    width: 70%;
  }
  .mobile-header-bg-color--golden {
    box-shadow: none;
  }
  .mobile-header {
    padding: 0;
  }
  #top_header {
    display: none;
  }
  .btn_media_viewmore {
    margin: 20px 0;
  }
  .mobileSectionOn {
    display: none !important;
  }
  #furniture_banner .slick-dots {
    bottom: 2rem;
    width: 100%;
    left: 0;
  }
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
  #ingredient_list .ingredient_infoCard {
    height: 250px;
  }
  #ingredient_list .ingredient_img {
    height: 250px;
    object-fit: cover;
  }
  #Ingredient_area .ingredient_box {
    padding: 1rem;
  }
  #Ingredient_area .ingredient_box h3 {
    margin-bottom: 1rem;
    font-size: 18px;
  }
  #Ingredient_area .ingredient_box p {
    font-size: 14px;
  }
  .carousel .thumbs-wrapper {
    margin: 20px 0;
    display: none;
  }

  #bloglist_area {
    padding: 30px 0 50px 0 !important;
  }
  .bgColor {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 2.5rem;
  }

  .furniture_slider .slider_cat_name h2 {
    font-size: 20px;
    height: auto;
  }
  .to_big_why_content_cat_name h2 {
    font-size: 20px;
    height: 100%;
  }
  #furniture_banner .slick-active .furniture_slider_content a {
    font-size: 10px;
    margin-bottom: 2.5rem;
  }
  .imageHolderClinical {
    left: 0%;
  }
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 5rem;
  }
  #aboutClinicalStudy .imageHolderClinical img {
    width: 379px;
    height: 290px;
  }
  #aboutClinicalStudy .imageHolderClinical::after {
    bottom: 15rem;
    left: -7rem;
  }
  #aboutClinicalStudy .imageHolderClinical::before {
    top: -12rem;
    left: -1rem;
  }
  .imageHolderClinicalcontainer {
    margin-bottom: 6rem;
  }
  .lowerTextClinicalStudies {
    margin: auto;
    margin-top: 3rem;
    margin-left: 3rem;
  }
  .blog_list_box_img {
    margin-left: 0;
  }
  .upperTextClinicalStudies {
    padding: 0 2rem;
  }
  .blogImgtopRight1 {
    height: 25.45rem;
    width: 100%;
  }
  #aboutClinicalStudy .upperTextClinicalStudies h2 {
    font-size: 20px;
    line-height: 40.32px;
    font-weight: 400;
  }
  #aboutClinicalStudy .upperTextClinicalStudies h4 {
    font-size: 24px;
    line-height: 40.32px;
    font-weight: 400;
  }
  #aboutClinicalStudy .upperTextClinicalStudies p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
  }

  #aboutClinicalStudy .headingStudiesResult h4 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 30.24px;
  }
  #aboutClinicalStudy .headingStudiesEffects h4 {
    color: white;
    font-size: 16px;
    margin-left: -1rem;
    font-weight: 300;
    line-height: 30.24px;
  }
  /* valuesection clinical study */

  .Clinical_content_list h2 {
    font-size: 24px;
    font-weight: 600;
  }

  .Clinical_content_list h4 {
    font-size: 20px;
    font-weight: 600;
  }
  #ValueOfIngrediantSection .Clinical_content_paras {
    color: #686868;
    margin: 2rem auto;
    font-size: 16px;
    font-weight: 400;
  }

  /* clinical study end */

  .ingredients-middle p {
    font-size: 16px;
  }
  .ingredients-middle h4 {
    font-size: 20px;
  }
  .ingredients-middle h2 {
    font-size: 24px;
  }
  .faqColorMobile {
    background-color: rgb(243, 241, 241);
    color: black;
  }
  /* #medialist_area .listTabeMedia {
    margin-left: 10px;
  } */
  /* productslist section */
  #to_days_area:after {
    width: 500px;
    height: 300px;
    top: -10%;
    left: -50%;
  }
  /* productlist section end */

  .bgColortop {
    background-color: #41889d;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .header-action-link li a i {
    color: #fff;
  }
  .bgColorbottom {
    background-color: #f5f5f5;
  }

  #about-top .about_top_left_content h2 {
    font-size: 24px;
    font-weight: 600;
  }
  #about-top .about_top_left_content h4 {
    font-size: 20px;
    font-weight: 600;
  }
  #about-top .about_top_left_content p p {
    font-size: 16px;
  }
  /* skin beauty section */
  .about_top_img_skinBeauty_mobile,
  .about_top_img_mobile {
    width: 100%;
    margin: auto;
  }
  .meetCreatorLeft_mobileOn {
    background-color: rgba(243, 241, 241, 0.8);
    margin-top: -120px;
    width: 300px;
  }
  .meetCreatorLeft_mobileOn h2 {
    font-size: 24px;
  }
  .footerLogoImg {
    width: 180px;
  }
  #about-top .meetCreatorLeft_mobileOn p p {
    font-size: 16px;
    font-weight: 300;
  }
  .meetCreatorLeft_mobileOn a {
    font-size: 18px;
    color: black;
    margin: auto;
    padding: 10px 40px;
    border: solid 1px black;
  }
  #common_banner_one {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 20rem;
  }

  #to_days_area:before {
    width: 278px;
    height: 266px;
  }

  .about_top_left_content p ul li {
    font-size: 16px;
  }
  .breadcrumb_list_mobile {
    margin: auto;
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
  }
  .breadcrumb_list_mobile ul li,
  #breadcrumb_list_mobile_home {
    color: white;
    font-weight: 600;
  }
  #mobileHadingColor {
    color: #41889d;
  }
  .breadcrumb_list_mobile ul .active {
    color: #87cbc2;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .page_title p,
  .about_top_left_content p p {
    font-size: 16px;
    font-weight: 400;
  }
  .about_top_left_content h3 {
    font-size: 24px;
  }
  .MobileImageBeauty,
  .skinBeautyImage {
    height: 800px;
    object-fit: cover;
  }
  .instargramGallery {
    height: 300px;
    width: 208px;
    object-fit: cover;
  }
  .MobileImageBeautyUpperSection {
    margin-top: -700px;
  }
  .skinbeauty_bottom_content h4:before {
    height: 6px;
    border-radius: 3px;
    width: 12%;
  }
  .MobileImageBeautyUpperSection p {
    font-size: 16px;
  }
  /* skin beauty section end*/
  /* immunity sectin start */
  .about_top_left_content p {
    font-size: 20px;
  }
  .about_top_left_content h2 {
    font-size: 24px;
  }
  .about_top_left_content p p,
  .about_top_left_content p ul li {
    font-size: 20px;
  }
  .immunity_content_list h2 {
    font-size: 24px;
  }
  .immunity_content_list p {
    font-size: 20px;
  }
  /* immunity sectin end */
  .offside-menu {
    font-size: 25px;
    cursor: pointer;
  }

  .mobile-bottom_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-right: -7px; */
    /* padding-top: 15px;
    padding-bottom: 13px; */
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
  }

  .searchInputContainer {
    position: relative;
  }
  .searchInputIcon {
    position: absolute;
    font-size: 23px;
    left: 10px;
    top: 13px;
  }
  .searchInputField {
    width: 350px;
    height: 48px;
    margin: auto;
    margin-right: 1.2rem;
    outline: none;
    border-color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 6px;
    padding: 10px 40px;
    animation-name: example;
    animation-duration: 1s;
    animation-direction: alternate;
  }
  @keyframes example {
    from {
      width: 1px;
    }
    to {
      width: 350px;
    }
  }
  /* .mobile-bottom_side ul {
    display: flex;
    list-style: none;
    gap: 35px;
  } */
  .cartIconMbl {
    display: inline-flex;
  }
  .mobileIcon li,
  .mobileIcon li a {
    font-size: 25px;
    font-weight: 300;
  }
  .mobileIcon li a {
    font-size: 16px;
    font-weight: 300;
  }
  /* ingrediant section */
  .ingredient_img {
    width: 100%;
  }
  .ingredient_hover1,
  .ingredient_hover2 {
    /* width: 350px; */
  }
  /* uniqueness section */
  .skinbeauty_bottom_content {
    margin-top: 20px;
  }
  /* ingrediant section */

  /* copyright-section */
  #copyright_one .Copyright_items {
    display: flex;
    justify-content: start;
    align-items: left;
    margin: auto;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 12px;
  }
  .copytright_border {
    border-top: 1px solid #ededed;
    width: 100%;
  }
  #copyright_one .linksSection {
    padding-bottom: 10px;
  }
  #copyright_one .bySection {
    padding-top: 10px;
  }
  /* book a session section  */
  .book_session_btn a {
    padding: 12px 35px;
    font-size: 20px;
  }
  /* footer middle */
  #giftdelivery_section img {
    width: 30px;
  }
  #giftdelivery_section h6 {
    font-size: 0.6rem;
  }
  .mc-form {
    flex-direction: column;
    gap: 30px;
  }
  .mc-form .mobile_btn_footer {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
  /*  */
  .royalty_program_btn a {
    padding: 15px 20px;
    font-size: 20px;
  }
  /* //media sectin */
  .viewMore_mobile a {
    margin: auto;
    font-size: 20px;
    padding: 15px 40px;
    font-weight: 700;
    line-height: 28px;
  }
  /* meet the inspiraton section text */
  .MeetInspiration_content a {
    font-size: 8.3px;
    font-weight: bold;
  }
  

  /* bigwhy section home */
  .to_big_why_content {
    font-size: 16px;
    margin: 0 0.5rem;
  }
  .to_big_why_content a {
    margin: auto;
    font-size: 18px;
    border-radius: 0;
    border: solid 3.1px;
    padding: 5px 35px;
  }

  .to_big_why_content .btnBigWrape {
    text-align: center;
  }
  /* according slider section homepage */
  .accordion-sliderMobile {
    margin: auto;
    padding: 0;
    margin: none;
  }
  /* according slider section homepage end*/

  .view_all_arae {
    position: inherit;
    text-align: right;
    top: 0;
    padding-bottom: 30px;
  }

  .slider_button_style .owl-nav {
    top: 0;
    position: inherit;
  }

  .image-input label {
    width: 70%;
  }
  .furniture_slider_content h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  .furniture_slider_content h2 {
    padding: 22px 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
  }
  .furniture_slider_content p:nth-child(1) {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  .furniture_slider_content p:nth-child(2) {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  .furniture_slider_content .btn_discover {
    font-weight: 500;
    line-height: 25.2px;
  }
  /* why big content */
  /* .to_big_why_content {
    margin-top: -3rem;
  } */
  .cookie-bar {
    bottom: -150px;
  }

  .account_setting_heading h2 {
    font-size: 24px;
  }

  .hero_img img {
    position: absolute;
    top: -377px;
    right: 0;
  }

  .comment_info .children {
    margin-left: 20px;
  }

  .comment_user img {
    height: auto;
    max-width: 55px;
  }

  .erorr_wrapper h1 {
    font-size: 100px;
    line-height: 60px;
  }

  .empaty_cart_area h2 {
    font-size: 28px;
  }

  .empaty_cart_area h3 {
    padding-top: 5px;
    font-size: 18px;
  }

  .row .col-lg-3:last-child .service_promo_single_item {
    margin-bottom: 0;
  }

  .product_count_one {
    display: inherit;
  }

  .product_count_one a {
    margin-top: 20px;
  }

  #footer_one .footer_left_side p {
    padding-right: 0px;
  }

  .product_var_one_text {
    left: 10px;
  }

  .product_var_one_text_center {
    left: 10px;
  }

  .banner_text_one h1 {
    font-size: 68px;
    line-height: 76px;
  }

  #blog_area_one .container .row .col-lg-4 .blog_one_item {
    padding-top: 30px;
  }

  #blog_area_one .container .row .col-lg-4:first-child .blog_one_item {
    padding-top: 0px;
  }

  .slider_arrows_one .owl-nav {
    position: inherit;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    top: 0;
  }

  .mobile-menu-logo .logo {
    width: 120px;
    overflow: hidden;
  }

  .copyright_left {
    text-align: center;
  }

  #copyright_one .copyright_right {
    text-align: center;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-100 {
    padding-top: 100px;
  }

 

  #sizes_input .custom_boxed {
    margin-right: 8px;
  }

  #related_product.pb-100 {
    padding-bottom: 30px !important;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .center_heading h2 {
    font-size: 30px;
  }

  .center_heading p {
    padding-top: 5px;
  }


  .background_bg {
    background-position: 65% 100%;
    background-size: cover;
  }
  /* #furniture_banner .slick-dots {
    bottom: 22rem;
  } */
  #MeetInspiration_area .mobileSizeDiscover {
    font-size: 16px;
    font-weight: 500;
  }
  .lowerTextClinicalStudies {
    margin-left: 7rem;
  }
}
@media (min-width: 574px) and (max-width: 766px) {
  .NoneShellSee {
    display: none;
  }
}
@media (max-width: 575px) {
  .listTabeMediaRight {
    margin-right: 20px;
  }
  .tables_area {
    width: 320px;
  }

  .BigWhy_ImageCarosalContainer{
    height: 256px;
  }
}

@media (max-width: 480px) {
  /* #furniture_banner .slick-dots {
    bottom: 25rem;
  } */
  .header-action-link li a i {
    color: #000;
  }
  .BigWhy_ImageCarosalContainer{
    height: 205px;
  }
  .bgColor {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0 !important;
  }
  .offside-menu i {
    color: #fff;
  }
  .mobileSectionOn {
    display: block !important;
  }
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 0rem;
  }
  /* #medialist_area .listTabeMedia {
    margin-left: 0;
  } */
  .searchInputField {
    width: 250px;
    animation-name: example;
    border-color: #000 !important;
    border: 1px solid #000 !important;
  }
  @keyframes example {
    from {
      width: 1px;
    }
    to {
      width: 220px;
    }
  }
  .drodo-responsive-nav .logo {
    position: relative;
    width: 39%;
    z-index: 999;
    top: 11px;
  }

  /* .furniture_slider {
    padding: 0 20px;
  } */

  .product_filter {
    padding-bottom: 0px;
  }

  .product_shot {
    display: inherit;
  }

  .customs_selects {
    padding: 15px 0;
  }

  .product_shot_view ul li:first-child {
    padding-left: 0px;
  }

  .mobile-menu-logo .logo {
    width: 100px;
    overflow: hidden;
  }

  .header-action-link li {
    margin-right: 15px;
  }

  .btn_sm {
    padding: 10px 12px;
  }

  .lowerTextClinicalStudies {
    margin-left: 4rem;
  }

  .cartIconMbl {
    display: inline-flex;
  }
  .mobileIcon li,
  .mobileIcon li a {
    font-size: 25px;
    font-weight: 300;
  }
  .mobileIcon li a {
    font-size: 16px;
    font-weight: 300;
  }
  .searchInputContainer {
    position: relative;
  }
  .searchInputIcon {
    position: absolute;
    font-size: 23px;
    left: 10px;
    top: 13px;
  }
  .searchInputField {
    width: 450px;
    height: 48px;
    margin: auto;
    margin-right: 1.2rem;
    outline: none;
    border-color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 6px;
    padding: 10px 40px;
    animation-name: example;
    animation-duration: 1s;
    animation-direction: alternate;
  }
  .mobile-bottom_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-right: -7px; */
    /* padding-top: 15px;
    padding-bottom: 13px; */
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
  }
  .mobileSectionOn {
    display: block;
  }
  .mobileSectionOff {
    display: none;
  }
  .bgColor {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 2.5rem;
  }
  .bgColorbottom {
    background-color: #f5f5f5;
  }
  #top_header {
    display: none;
  }
  #furniture_banner .slick-dots {
    bottom: 20rem;
    left: 0;
    width: 100%;
  }
  .footer_left_side,
  .footer_one_widget {
    text-align: left !important;
    margin-bottom: 20px;
  }
  .margin_10_minus {
    margin-left: -10px;
  }

  #to_days_area:after {
    width: 100%;
  }

  #my-accordion .panels {
    height: 100% !important;
    display: block;
    overflow: visible;
  }

  #my-accordion .panels .panel .row .content {
    width: 90%;
    margin: 0 auto;
    padding: 60px 0;
  }

  #medialist_area .tabs_center_button ul li a {
    padding: 8px;
  }

  .tabs_center_button .nav-tabs li a {
    font-size: 15px;
  }

  #MeetInspiration_area .MeetInspiration_content {
    padding: 30px;
  }

  .btn_media_viewmore {
    text-align: left;
  }

  .blog_one_item {
    margin-bottom: 9rem;
  }

  .footer_social_div,
  .footer_newsletter_div {
    padding-left: 0;
    padding-right: 0;
  }

  .to_big_why_content_cat_name {
    display: none;
  }

  .to_big_why_content {
    padding: 15px;
  }
  #Gift_card_Form input {
    width: 80%;
  }

  .mb-none {
    display: none;
  }

  #first_order {
    order: 2;
  }

  #second_order {
    order: 1;
  }

  #third_order {
    order: 4;
  }

  #fourth_order {
    order: 3;
  }

  .page_title h2 {
    font-size: 28px;
  }

  .skinbeauty_bottom_content {
    padding: 30px;
  }

  .skinbeauty_bottom_content h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .page_title {
    padding-bottom: 0;
  }

  .product_instr {
    margin-bottom: 30px;
  }

  .about_top_img {
    margin-top: 30px;
  }

  .usage_box {
    margin-bottom: 30px;
    height: auto;
  }

  .about_top_left_content h2 {
    font-size: 28px;
  }

  .packag_img_flex {
    display: block;
  }

  .packag_img_flex img {
  }
  .page_title h2 .right-bootom-gif::after {
    display: none;
  }

  .meet-creator-left {
    padding: 30px 30px;
    width: 100%;
  }
  .bgColortop {
    background-color: #41889d;
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
  }
  .lowerTextClinicalStudies {
    margin-left: 6rem;
  }
  /* footer */
  .ArabicLogoParagraph {
    text-align: right;
  }
  .usefullLinks {
    text-align: right;
  }
  .footerarabicmiddle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
  }
  .footer_left_side_icon .arabicLayout {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
  }
  .footer_left_side_icon ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 3px;
  }
  .footer_left_side_icon ul li a {
    border: solid 1px white;
    padding: 7px 10px;
    text-align: center;
  }
  /* media section */
  .btn_media_viewmore {
  }
  .btn_media_viewmore a {
    padding: 5px 8px;
  }
  /* home section */
  .blog_text .heading {
    padding-top: 0;
  }
  .blog_text .para {
    padding-top: 0;
    min-height: 60px;
    height: auto;
  }
  .left_mobile {
    direction: rtl;
  }
  .ingredient_hover1 img,
  .ingredient_hover3 img,
  .ingredient_hover6 img,
  .ingredient_hover1 img,
  .ingredient_hover9 img,
  .ingredient_hover1,
  .ingredient_hover2 {
    /* width: 260px; */
  }
  .top_header_left {
    text-align: center;
  }

  .right_list_fix {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .about_top_img:after {
    width: 160px;
    height: 200px;
    top: 0;
    left: -5%;
  }

  .tabs_left_button .nav-tabs {
    border-bottom: none;
    width: 100%;
    justify-content: center;
    position: inherit;
    right: 0;
    top: 0;
    padding-bottom: 30px;
  }

  .top-header-menu {
    text-align: center;
  }

  .top-header-contact-info {
    text-align: center;
  }

 

  .image-input label {
    width: 50%;
  }

  .invoice-wrapper .authorise-sign {
    position: relative;
    text-align: center;
  }

  .invoice-wrapper .buttons {
    padding-top: 20px;
  }

  .theme-invoice-1 .invoice-header .detail-bottom ul {
    display: block;
  }

  .theme-invoice-4 .invoice-wrapper .invoice-footer ul {
    display: block;
    margin-bottom: -20px;
  }

  .theme-invoice-4 .invoice-wrapper .invoice-footer ul li {
    margin-bottom: 20px;
  }

  .theme-invoice-4 .title-sec .title {
    position: relative;
    text-align: center;
    margin-top: 20px;
  }

  .blog_post_wrapper {
    padding-bottom: 30px;
  }

  .cookie-bar {
    display: inherit;
    text-align: center;
    bottom: -107px;
  }

  .row .col-lg-4 .product_variation_one_boxed:last-child {
    margin-bottom: 0px;
  }

  .row .col-lg-4:first-child .product_variation_one_boxed:last-child {
    margin-bottom: 30px;
  }

  #blog_area_one .container .row .col-lg-4:last-child .blog_one_item {
    padding-top: 30px;
  }

  .offcanvas-cart-action-button {
    display: inherit;
  }

  .offcanvas-cart-action-button li {
    margin-bottom: 15px;
  }

  .banner_text_one {
    position: relative;
    z-index: 9;
  }

  .cookie-bar p {
    font-size: 12px;
    line-height: 19px;
  }

  .mobile-right-side ul li a i {
    font-size: 17px;
  }
  /* .search_width img {
    width: 17px;
  } */
  .page_title h2 {
    font-size: 24px;
  }
  .page_title p,
  .about_top_left_content p {
    font-size: 14px;
  }
  .about_top_left_content h3 {
    font-size: 24px;
  }
  .about_top_left_content p ul li::before {
    width: 20px;
    top: 10px;
  }
  .register_program_form h2{
    font-size: 24px;
    width: max-content;
  }
  #Ingredient_area .ingredient_title h2 {
    font-size: 30px;
  }
  .uniqueness_heading {
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    background: linear-gradient(to left, #cd7a26ba, #b56b1f, #b56b1fd4);

    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    margin-bottom: 20px;
    text-shadow: rgba(181, 107, 31, 0.5) 0px 0px 1.5px;
  }
  .celebrate_essense_bullitsize p ul li::before {
    width: 20px;
    top: 10px;
  }
  .celebrate_essense_bullitsize p ul li,
  .celebrate_essense_bullitsize p p,
  .packaging_psize p p {
    font-size: 13.5px;
  }

  .meet-creator-left {
    padding: 20px 30px;
    margin-bottom: 8px;
    width: 350px;
  }
  .meet-creator-left h4 {
    font-size: 20px;
  }
  .meet-creator-left h2 {
    font-size: 27px;
  }
  .meet-creator-left p p,
  .meet-creator-left a {
    font-size: 14px;
  }
  .book_session_btn a {
    padding: 5px 20px;
    font-size: 13px;
  }
  .royalty_program_btn a {
    padding: 5px 20px;
    font-size: 13px;
  }

  #aboutClinicalStudy .imageHolderClinical::after {
    bottom: 14rem;
    left: -5rem;
  }
  #aboutClinicalStudy .imageHolderClinical::before {
    top: -18rem;
    left: -12rem;
  }

  #aboutClinicalStudy .imageHolderClinical img {
    width: 479px;
    height: 350px;
  }
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 6rem;
  }
}
 
@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
  #ingredient_list .ingredient_infoCard {
    height: 270px;
  }
  #ingredient_list .ingredient_img {
    height: 270px;
    object-fit: cover;
  }
  #Ingredient_area .ingredient_box {
    padding: 1rem;
  }
  #Ingredient_area .ingredient_box h3 {
    margin-bottom: 1rem;
    font-size: 18px;
  }
  #Ingredient_area .ingredient_box p {
    font-size: 14px;
  }
  .carousel .thumbs-wrapper {
    margin: 20px 0;
    display: none;
  }
  #to_big_why_row {
    margin-top: -3rem;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0px;
    float: none;
  }
  .to_big_why_content {
    margin-right: 0;
  }
  .ptb-60 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  #bloglist_area {
    padding: 30px 0 50px 0 !important;
  }
  .bgColor {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 2.5rem;
  }

  .furniture_slider .slider_cat_name h2 {
    font-size: 20px;
    height: auto;
  }
  .to_big_why_content_cat_name h2 {
    font-size: 20px;
    height: 100%;
  }
  #furniture_banner .slick-active .furniture_slider_content a {
    font-size: 10px;
  }
  .imageHolderClinical {
    left: 0%;
  }
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 5rem;
  }
  #aboutClinicalStudy .imageHolderClinical img {
    width: 379px;
    height: 290px;
  }
  #aboutClinicalStudy .imageHolderClinical::after {
    bottom: 15rem;
    left: -7rem;
  }
  #aboutClinicalStudy .imageHolderClinical::before {
    top: -12rem;
    left: -1rem;
  }
  .imageHolderClinicalcontainer {
    margin-bottom: 6rem;
  }
  .lowerTextClinicalStudies {
    margin: auto;
    margin-top: 3rem;
    margin-left: 3rem;
  }
  .blog_list_box_img {
    margin-left: 0;
  }
  .upperTextClinicalStudies {
    padding: 0 2rem;
  }
  .blogImgtopRight1 {
    height: 25.45rem;
    width: 100%;
  }
  #aboutClinicalStudy .upperTextClinicalStudies h2 {
    font-size: 20px;
    line-height: 40.32px;
    font-weight: 400;
  }
  #aboutClinicalStudy .upperTextClinicalStudies h4 {
    font-size: 24px;
    line-height: 40.32px;
    font-weight: 400;
  }
  #aboutClinicalStudy .upperTextClinicalStudies p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
  }

  #aboutClinicalStudy .headingStudiesResult h4 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 30.24px;
  }
  #aboutClinicalStudy .headingStudiesEffects h4 {
    color: white;
    font-size: 16px;
    margin-left: -1rem;
    font-weight: 300;
    line-height: 30.24px;
  }
  /* valuesection clinical study */

  .Clinical_content_list h2 {
    font-size: 24px;
    font-weight: 600;
  }

  .Clinical_content_list h4 {
    font-size: 20px;
    font-weight: 600;
  }
  #ValueOfIngrediantSection .Clinical_content_paras {
    color: #686868;
    margin: 2rem auto;
    font-size: 16px;
    font-weight: 400;
  }

  /* clinical study end */

  .ingredients-middle p {
    font-size: 16px;
  }
  .ingredients-middle h4 {
    font-size: 20px;
  }
  .ingredients-middle h2 {
    font-size: 24px;
  }
  .faqColorMobile {
    background-color: rgb(243, 241, 241);
    color: black;
  }
  /* #medialist_area .listTabeMedia {
    margin-left: 10px;
  } */
  /* productslist section */
  #to_days_area:after {
    width: 500px;
    height: 300px;
    top: -10%;
    left: -50%;
  }
  /* productlist section end */

  .bgColortop {
    background-color: #41889d;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .bgColorbottom {
    background-color: #f5f5f5;
  }

  #about-top .about_top_left_content h2 {
    font-size: 24px;
    font-weight: 600;
  }
  #about-top .about_top_left_content h4 {
    font-size: 20px;
    font-weight: 600;
  }
  #about-top .about_top_left_content p p {
    font-size: 16px;
  }
  /* skin beauty section */
  .about_top_img_skinBeauty_mobile,
  .about_top_img_mobile {
    width: 100%;
    margin: auto;
  }
  .meetCreatorLeft_mobileOn {
    background-color: rgba(243, 241, 241, 0.8);
    margin-top: -120px;
    width: 300px;
  }
  .meetCreatorLeft_mobileOn h2 {
    font-size: 24px;
  }
  .footerLogoImg {
    width: 180px;
  }
  #about-top .meetCreatorLeft_mobileOn p p {
    font-size: 16px;
    font-weight: 300;
  }
  .meetCreatorLeft_mobileOn a {
    font-size: 18px;
    color: black;
    margin: auto;
    padding: 10px 40px;
    border: solid 1px black;
  }
  #common_banner_one {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 20rem;
  }

  #to_days_area:before {
    width: 278px;
    height: 266px;
  }
  section#MeetInspiration_area::after {
    content: "";
    background-image: none;
  }
  .about_top_left_content p ul li {
    font-size: 16px;
  }
   .productsTabDetials ul li{
    margin-left: -33px;
  } 
  .productsTabDetialsArabic ul li{
    margin-right: -33px;
  }
  .breadcrumb_list_mobile {
    margin: auto;
    font-size: 22px;
    margin-top: 20px;
    font-weight: 400;
  }
  #Ingredient_area .ingredient_btn_readMore {
    font-size: 14px;
   }
  .breadcrumb_list_mobile ul li,
  #breadcrumb_list_mobile_home {
    color: white;
    font-weight: 600;
  }
  #mobileHadingColor {
    color: #41889d;
  }
  .breadcrumb_list_mobile ul .active {
    color: #87cbc2;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .page_title p,
  .about_top_left_content p p {
    font-size: 16px;
    font-weight: 400;
  }
  .about_top_left_content h3 {
    font-size: 24px;
  }
  .MobileImageBeauty,
  .skinBeautyImage {
    height: 800px;
    object-fit: cover;
  }
  .instargramGallery {
    height: 300px;
    width: 208px;
    object-fit: cover;
  }
  .MobileImageBeautyUpperSection {
    margin-top: -700px;
  }
  .skinbeauty_bottom_content h4:before {
    height: 6px;
    border-radius: 3px;
    width: 12%;
  }
  .MobileImageBeautyUpperSection p {
    font-size: 16px;
  }
  /* skin beauty section end*/
  /* immunity sectin start */
  .about_top_left_content p {
    font-size: 20px;
  }
  .about_top_left_content h2 {
    font-size: 24px;
  }
  .about_top_left_content p p,
  .about_top_left_content p ul li {
    font-size: 16px;
  }
  .immunity_content_list h2 {
    font-size: 24px;
  }
  .immunity_content_list p {
    font-size: 20px;
  }
  /* immunity sectin end */
  .offside-menu {
    font-size: 25px;
    cursor: pointer;
  }

  .mobile-bottom_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-right: -7px; */
    /* padding-top: 15px;
    padding-bottom: 13px; */
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
  }

  .searchInputContainer {
    position: relative;
  }
  .searchInputIcon {
    position: absolute;
    font-size: 23px;
    left: 10px;
    top: 13px;
  }
  .searchInputField {
    width: 350px;
    height: 48px;
    margin: auto;
    margin-right: 1.2rem;
    outline: none;
    border-color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 6px;
    padding: 10px 40px;
    animation-name: example;
    animation-duration: 1s;
    animation-direction: alternate;
  }
  @keyframes example {
    from {
      width: 1px;
    }
    to {
      width: 350px;
    }
  }
  /* .mobile-bottom_side ul {
    display: flex;
    list-style: none;
    gap: 35px;
  } */
  .cartIconMbl {
    display: inline-flex;
  }
  .mobileIcon li,
  .mobileIcon li a {
    font-size: 25px;
    font-weight: 300;
  }
  .mobileIcon li a {
    font-size: 16px;
    font-weight: 300;
  }
  /* ingrediant section */
  .ingredient_img {
    width: 100%;
  }
  .ingredient_hover1,
  .ingredient_hover2 {
    /* width: 350px; */
  }
  /* uniqueness section */
  .skinbeauty_bottom_content {
    margin-top: 20px;
  }
  /* ingrediant section */

  /* copyright-section */
  #copyright_one .Copyright_items {
    display: flex;
    justify-content: start;
    align-items: left;
    margin: auto;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 12px;
  }
  #copyright_one .Copyright_items_mobile,
  #copyright_one .designByMobile {
    display: flex;
    justify-content: center;
    align-items: left;
    margin: auto;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 12px;
    flex-direction: row;
  }
  #copyright_one .Copyright_items_mobile li {
    text-align: center;
    width: 40%;
  }
  .copytright_border {
    border-top: 1px solid #ededed;
    width: 100%;
  }
  #copyright_one .linksSection {
    padding-bottom: 10px;
  }
  #copyright_one .bySection {
    padding-top: 10px;
  }
  /* book a session section  */
  .book_session_btn a {
    padding: 10px 30px;
    font-size: 16px;
  }
  /* footer middle */
  #giftdelivery_section img {
    width: 30px;
  }
  #giftdelivery_section h6 {
    font-size: 0.6rem;
  }
  .mc-form {
    flex-direction: column;
    gap: 30px;
  }
  .mc-form .mobile_btn_footer {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
  /*  */
  .royalty_program_btn a {
    padding: 15px 20px;
    font-size: 20px;
  }
  /* //media sectin */
  .viewMore_mobile a {
    margin: auto;
    font-size: 20px;
    padding: 15px 40px;
    font-weight: 700;
    line-height: 28px;
  }
  /* meet the inspiraton section text */
  .MeetInspiration_content a {
    font-size: 8.3px;
    font-weight: bold;
  }

  /* bigwhy section home */
  .to_big_why_content {
    font-size: 16px;
    margin: 0 0.5rem;
  }
  .to_big_why_content a {
    margin: auto;
    font-size: 18px;
    border-radius: 0;
    border: solid 3.1px;
    padding: 5px 35px;
  }

  .to_big_why_content .btnBigWrape {
    text-align: center;
  }
  /* according slider section homepage */
  .accordion-sliderMobile {
    margin: auto;
    padding: 0;
    margin: none;
  }
  /* according slider section homepage end*/

  .view_all_arae {
    position: inherit;
    text-align: right;
    top: 0;
    padding-bottom: 30px;
  }

  .slider_button_style .owl-nav {
    top: 0;
    position: inherit;
  }

  .image-input label {
    width: 70%;
  }
  .furniture_slider_content h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  .furniture_slider_content h2 {
    padding: 22px 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
  }
  .furniture_slider_content p:nth-child(1) {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  .furniture_slider_content p:nth-child(2) {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  .furniture_slider_content .btn_discover {
    font-weight: 500;
    line-height: 25.2px;
  }
  /* why big content */
  /* .to_big_why_content {
    margin-top: -3rem;
  } */
  .cookie-bar {
    bottom: -150px;
  }

  .account_setting_heading h2 {
    font-size: 24px;
  }

  .hero_img img {
    position: absolute;
    top: -377px;
    right: 0;
  }

  .comment_info .children {
    margin-left: 20px;
  }

  .comment_user img {
    height: auto;
    max-width: 55px;
  }


  .erorr_wrapper h1 {
    font-size: 100px;
    line-height: 60px;
  }

  .empaty_cart_area h2 {
    font-size: 28px;
  }

  .empaty_cart_area h3 {
    padding-top: 5px;
    font-size: 18px;
  }

  .row .col-lg-3:last-child .service_promo_single_item {
    margin-bottom: 0;
  }
  .service_promo_single_item {
    min-height: 20rem;
  }
  .product_count_one {
    display: inherit;
  }
  #service_promo_area {
    margin-bottom: 3rem;
  }
  .product_count_one a {
    margin-top: 20px;
  }

  #footer_one .footer_left_side p {
    padding-right: 0px;
  }

  .product_var_one_text {
    left: 10px;
  }

  .product_var_one_text_center {
    left: 10px;
  }

  .banner_text_one h1 {
    font-size: 68px;
    line-height: 76px;
  }

  #blog_area_one .container .row .col-lg-4 .blog_one_item {
    padding-top: 30px;
  }

  #blog_area_one .container .row .col-lg-4:first-child .blog_one_item {
    padding-top: 0px;
  }

  .slider_arrows_one .owl-nav {
    position: inherit;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    top: 0;
  }

  .mobile-menu-logo .logo {
    width: 120px;
    overflow: hidden;
  }

  .copyright_left {
    text-align: center;
  }

  #copyright_one .copyright_right {
    text-align: center;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-100 {
    padding-top: 100px;
  }

  #sizes_input .custom_boxed {
    margin-right: 8px;
  }

  #related_product.pb-100 {
    padding-bottom: 30px !important;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .center_heading h2 {
    font-size: 30px;
  }

  .center_heading p {
    padding-top: 5px;
  }

  .background_bg {
    background-position: 65% 100%;
    background-size: cover;
  }
  /* #furniture_banner .slick-dots {
    bottom: 22rem;
  } */
  #MeetInspiration_area .mobileSizeDiscover {
    font-size: 16px;
    font-weight: 500;
  }
  .lowerTextClinicalStudies {
    margin-left: 7rem;
  }
}

@media (max-width: 480px) {
  #aboutClinicalStudy .imageHolderClinical {
    margin-left: 0rem;
  }

  .MobileArabicLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-left: 5px;
  }
  .MobileEngLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .MobileEngLayoutDown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ArabicLayoutbuttomICon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .searchInputField {
    width: 230px;
    animation-name: example;
    border-color: #000 !important;
    border: 1px solid #000 !important;
  }
  @keyframes example {
    from {
      width: 1px;
    }
    to {
      width: 220px;
    }
  }
  .drodo-responsive-nav .logo {
    position: relative;
    width: 39%;
    z-index: 999;
    top: 11px;
  }

  .BigWhy_ImageCarosalContainer{
    height: 200px;
  }
  #to_big_why_row {
    margin-top: 0.1rem;
  }
  

  /* .furniture_slider {
    padding: 0 20px;
  } */
  #furniture_banner .slick-dots {
    top: 30rem;
  }

  .product_filter {
    padding-bottom: 0px;
  }

  .product_shot {
    display: inherit;
  }

  .customs_selects {
    padding: 15px 0;
  }
  h2 {
    font-size: 24px;
  }
  .product_shot_view ul li:first-child {
    padding-left: 0px;
  }

  .mobile-menu-logo .logo {
    width: 100px;
    overflow: hidden;
  }

  .header-action-link li {
    margin-right: 15px;
  }

  .btn_sm {
    padding: 10px 12px;
  }

  .lowerTextClinicalStudies {
    margin-left: 4rem;
  }
  .searchInputContainer {
    margin-left: 13px;
  }
}

@media (max-width: 361px) {
  .tables_area {
    width: 260px;
  }
  .searchInputField {
    width: 10rem;
    animation-name: example;
    border-color: #000 !important;
    border: 1px solid #000 !important;
  }
  @keyframes example {
    from {
      width: 1px;
    }
    to {
      width: 12rem;
    }
  }
  .mobile-menu-logo .logo {
    width: 100px;
    overflow: hidden;
  }

  .header-action-link li {
    margin-right: 10px;
  }

  .btn_sm {
    padding: 10px 5px;
  }
  /* #furniture_banner .slick-dots {
    bottom: 19rem;
  } */

  .BigWhy_ImageCarosalContainer{
    height: 157px;
  }
  
}
