/*
/*
@File: AndShop Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>
*******************************************
*/

/*================================================
Default CSS
=================================================*/

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"); 

 @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

@font-face {
  font-family: "Ogg-Light";
  font-weight: 300;
  src: local("Ogg-Light"),
    url("../fonts/ogg-font-family/Ogg-Light.ttf")
      format("TrueType OpenType fonts");
  font-display: swap;
}


html {
  scroll-behavior: smooth;
  /* make fallback font default */
  /* font-family: Georgia, serif; */
}
html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  font-display: swap;
  color: #000000;
  background-color: #ffffff;

}
.arabiclyoutd {
  direction: rtl;
}
.CardProductModelArabicLayout {
  text-align: right;
  padding-right: 50px;
}
#WhyUsTextArabic ul li {
  padding-right: 25px;
}
.englishlyoutd {
  direction: ltr;
}
.mmr-1 {
  margin-right: -10px;
}
#WhyUsTextArabic ul li:before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/common/Groupsign.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
  top: 6px;
}

.FlagIcon {
  width: 20px;
  margin: 0 10px;
}
img {
  max-width: 100%;
  height: auto;
}

.ingredientStyle:hover {
  color: var(--hover-color) !important;
  opacity: var(--hover-opacity);
}
.arabicposition_cart {
  position: absolute;
  margin-right: -15px;
  margin-top: 12px;
}
.payment_method .collapsed {
  width: 100% !important;
}
.mr-20 {
  margin-right: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-20 {
  padding-left: 20px;
}

.ptb-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.pb-150 {
  padding-bottom: 150px;
}

.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-30 {
  padding-top: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-30 {
  margin: 30px 0;
}
.mr-50perc {
  position: absolute;
  left: 20%;
}
.mr-70perc {
  position: absolute;
  left: 20%;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0.1s;
  transition: all 0.3s ease-in-out 0.1s;
  outline: 0 !important;
  color: var(---heading-color);
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-display: swap;

}

h1 {
  font-size: 80px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 22px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  font-weight: 300;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-display: swap;

  margin-bottom: 0;
}

p:last-child {
  margin-bottom: 0;
}

.btn-underline {
  text-decoration: none;
  position: relative;
}

.btn-underline:after {
  position: absolute;
  content: "";
  height: 2px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 110%;
  background: #000;
  bottom: -8px;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
  padding: 0;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

/* --Common Class Area start-- */

/* .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1440px;
} */

.img-zoom-hover img {
  /* transform-origin: 65% 75%; */
  transition: transform 1s, filter 0.5s ease-out;
}

.img-zoom-hover:hover img {
  transform: scale(1.1) rotate(1deg);
}

/* --Center Heading-- */
.center_heading {
  padding-bottom: 30px;
  text-align: center;
}

.center_heading h2 {
  font-weight: 300;
  position: relative;
  color: #fff;
}

#related_product .center_heading h2 {
  color: #000;
}

button.add-to-cart {
  /* width: 200px; */
  margin: auto;
  border-radius: 10px;
  font-size: 14px;
  /* box-shadow: -1px 7px 12px -4px rgba(171, 171, 171, 0.75); */
}

.center_heading p {
  padding-top: 10px;
}

/* --Center Heading Two-- */
.center_heading_two {
  padding-bottom: 30px;
  text-align: center;
}

.center_heading_two h2 {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.heading_border {
  display: block;
  background: var(--main-theme-color);
  height: 3px;
  width: 150px;
  margin: 10px auto 0 auto;
}
.footer_social__heading {
  font-size: 20px;
}
/* --Left Heading Three-- */
.left_heading_three {
  text-align: left;
}

.left_heading_three h2 {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

/* --Slider Arrow Style--*/

.slider_arrows_one .owl-nav {
  top: -45px;
  position: absolute;
  right: 0;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev {
  background: var(--main-theme-color);
  color: #fff;
  border: none;
  padding: 0 !important;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  box-shadow: 0px 1px 3px -1px #000;
  transition: 0.4s all ease-in-out;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next:hover,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev:hover {
  background: #fff;
  color: #000;
}

/* --Button-- */
.bg-gray {
  background: #f6f6f6;
}

.bg-whites {
  background-color: #fff;
  color: #000;
}

.bg-whites:hover {
  color: #fff;
  background-color: #000;
}

.bg-black {
  background-color: #41889d;
  color: #fff;
  border: 1px solid #fff;
}

.bg-black:hover {
  color: #41889d;
  background-color: #fff;
  border: 1px solid #41889d;
}

.primaryColor {
  color: #41889d;
}
.textLineThroght {
  text-decoration: line-through;
}
.theme-btn-one {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
}
.filter_btn {
  border-radius: 7px;
}
.btn_md {
  padding: 16px 35px;
}
.btn_nm {
  padding: 10px 20px;
}
.orderDetials td h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog_list_box_img {
  height: 160px;
  width: 131px;
  margin: auto;
}
.blog_list_box_img img {
  height: 120px;
  width: 120px;
}
.blogListImage2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_sm {
  padding: 10px 16px;
}

.btn-black-overlay {
  background-color: #000;
  color: #fff;
}

.btn-black-overlay:hover {
  background-color: #262626;
  color: #fff;
}

#common_banner_one {
  padding: 13% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.breadcrumb_list ul {
  display: flex;
  align-items: center;
  margin: 15px 0;
  float: right;
}

.breadcrumb_list ul li {
  padding: 0 5px;
}

.breadcrumb_list ul li,
.breadcrumb_list ul li a {
  color: #4d4d4d;
  font-weight: 400;
  text-transform: uppercase;
}

.breadcrumb_list ul li:hover a {
  color: var(--main-theme-color);
}

.common_banner_text {
  text-align: center;
  color: #fff;
}

.common_banner_text h2 {
  color: #fff;
}

.common_banner_text ul {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.common_banner_text ul li {
  color: #fff;
}

.common_banner_text ul li.active {
  color: var(--main-theme-color);
}

.common_banner_text ul li.slash {
  padding: 0 10px;
}

.common_banner_text ul li a {
  color: #fff;
}

.common_banner_text ul li a:hover {
  color: var(--main-theme-color);
}

/* --Common Class Area start-- */

/*--Common input style--*/

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="date"],
select {
  min-height: 45px;
  background: #fff;
  border-color: #ddd;
  border: 1px solid #ddd;
}
.giftCardImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.GiftCardImage {
  margin: 5px auto;
  width: 80%;
}
textarea {
  background: #fff !important;
  border-color: #ddd !important;
  border: 1px solid #ddd !important;
}

.form-control:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
  background-color: #fff;
  outline: none;
  box-sizing: none;
  border: 1px solid var(--main-theme-color) !important;
}

button {
  border: none;
}

/* ------- --------------- */

/*Layout*/

/*****************************
* Layout - Header
*****************************/

.header-top-bg--black {
  background: #24262b;
}

.header-top-bg--white {
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-top-contact a,
.header-top-user-link a {
  line-height: 40px;
  margin-right: 40px;
  position: relative;
}

.header-top-contact a::after,
.header-top-user-link a::after {
  position: absolute;
  content: "|";
  left: calc(100% + 20px);
}

.header-top-contact a:last-child,
.header-top-user-link a:last-child {
  margin-right: 0;
}

.header-top-contact a:last-child::after,
.header-top-user-link a:last-child::after {
  display: none;
}

.header-top-contact-color--white a,
.header-top-user-link-color--white a {
  color: #fff;
}

.header-top-contact-color--white a::after,
.header-top-user-link-color--white a::after {
  color: #fff;
}

.header-top-contact-color--black a,
.header-top-user-link-color--black a {
  color: #24262b;
}

.header-top-contact-color--black a::after,
.header-top-user-link-color--black a::after {
  color: #24262b;
}

.header-top-contact-hover-color--green a:hover,
.header-top-user-link-hover-color--green a:hover {
  color: #047b42;
}

.header-top-contact-hover-color--aqua a:hover,
.header-top-user-link-hover-color--aqua a:hover {
  color: #66cec4;
}

.header-bottom {
  position: relative;
}

.header-bottom-color--golden {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header-bottom-color--white {
  background: #fff;
}

.header-bottom-color--black {
  background: #24262b;
}

.sticky-header.sticky.sticky-color--golden {
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sticky-header.sticky.sticky-color--white {
  background: #fff;
}

.sticky-header.sticky.sticky-color--black {
  background: #24262b;
}

/* .search_width img {
  width: 20px;
} */

/* Top Header Style */
#top_header {
  background-color: #41889d;
  padding: 3px 0px;
}

.top_header_left p {
  font-size: 14px;
  color: #0a0d14;
}

.top_header_left a {
  color: #fff;
  padding-left: 5px;
}

.top_header_left a i {
  margin-right: 5px;
}

.right_list_fix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right_list_fix li {
  padding-left: 26px;
}

.right_list_fix li:first-child {
  padding-left: 0;
}

.top_header_right ul li a {
  font-size: 14px;
}

.top_header_right ul li i {
  padding-right: 3px;
}

/*Main Menu Style*/

.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: #333;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.main-menu {
  position: relative;
  /* width: 68%; */
  width: 55%;
}

.main-menu nav > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-menu nav > ul > li {
  position: relative;
  display: inline-block;
  /* padding-left: 33px; */
  margin-right: 5px;
}

/* .main-menu nav>ul>.has-dropdown {
    margin-right: 5px;
} */

.main-menu nav > ul > .has-dropdown:last-child {
  margin-right: 0;
}

.main-menu nav > ul > .has-megaitem {
  position: static;
}

.main-menu nav > ul > li > a {
  display: block;
  line-height: 24px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.main-menu-style-4 nav > ul > li > a {
  padding: 10px 30px;
}

.sub-menu > li > a,
.mega-menu-sub > li > a {
  font-weight: 500;
  display: block;
  line-height: 35px;
  text-transform: capitalize;
  color: #777;
  position: relative;
  transition: all 0.5s ease;
}

.sub-menu > li > a:hover,
.mega-menu-sub > li > a:hover {
  transform: translateX(10px);
}

.main-menu .active {
  color: var(--main-theme-color) !important;
}

.sub-menu {
  position: absolute;
  min-width: max-content;
  padding: 25px 20px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu {
  position: absolute;
  min-width: 100%;
  padding: 25px 30px 30px 30px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu-inner {
  display: flex;
  justify-content: space-between;
}

.mega-menu-item-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
  color: #24262b;
}

.mega-menu-sub > li {
  display: block;
}

.main-menu nav > ul > li:hover .sub-menu,
.main-menu nav > ul > li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.menu-banner > a {
  display: block;
  width: 100%;
}

.menu-banner img {
  width: 100%;
}

/*Main Menu Link Normal Color*/

.menu-color--black nav > ul > li > a {
  color: #24262b;
}

.menu-color--black .sub-menu > li > a,
.menu-color--black .mega-menu-sub > li > a {
  color: #24262b;
}

.menu-color--black .mega-menu-item-title {
  color: #24262b;
}

.menu-color--white nav > ul > li > a {
  color: #fff;
}

.menu-color--white .sub-menu > li > a,
.menu-color--white .mega-menu-sub > li > a {
  color: #24262b;
}

.menu-color--white .mega-menu-item-title {
  color: #24262b;
}

/*Main Menu Link Hover Color*/

.menu-hover-color--golden nav > ul > li > a:hover {
  color: var(--main-theme-color);
}

.menu-hover-color--golden .sub-menu > li > a:hover,
.menu-hover-color--golden .mega-menu-sub > li > a:hover {
  color: var(--main-theme-color);
}

/* .menu-hover-color--golden .mega-menu-item-title:hover {
    color: var(--main-theme-color);
} */

.menu-hover-color--green nav > ul > li > a:hover {
  color: #047b42;
}

.menu-hover-color--green .sub-menu > li > a:hover,
.menu-hover-color--green .mega-menu-sub > li > a:hover {
  color: #047b42;
}

.menu-hover-color--green .mega-menu-item-title:hover {
  color: #047b42;
}

.menu-hover-color--pink nav > ul > li > a:hover {
  color: #ff365d;
}

.menu-hover-color--pink .sub-menu > li > a:hover,
.menu-hover-color--pink .mega-menu-sub > li > a:hover {
  color: #ff365d;
}

.menu-hover-color--pink .mega-menu-item-title:hover {
  color: #ff365d;
}

.menu-hover-color--aqua nav > ul > li > a:hover {
  color: #66cec4;
}

.menu-hover-color--aqua .sub-menu > li > a:hover,
.menu-hover-color--aqua .mega-menu-sub > li > a:hover {
  color: #66cec4;
}

.menu-hover-color--aqua .mega-menu-item-title:hover {
  color: #66cec4;
}

/* .header-logo {
  width: 10%;
} */

.header-logo .logo img {
  width: 100%;
  height: auto;
}

.header-action-link {
  /* display: flex;
  align-items: center; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-action-link li {
  margin-right: 20px;
}

.header-action-link li:last-child {
  margin-right: 0;
}

.header-action-link li a {
  font-size: 21px;
  position: relative;
  /* letter-spacing: -6px; */
}

.header-action-link li a span {
  display: inline-block;
  font-size: 11px;
  background: darkgoldenrod;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
  font-weight: 600;
  margin-left: -0.5rem;
}

.offside-about {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: normal !important;
  display: block;
  border-radius: 50%;
}

.offcanvas-close img {
  height: 18px;
}

/*Action Style Color Normal*/

.action-color--black.header-action-link li a {
  color: #24262b;
}

.action-color--black .offside-about {
  background: rgba(242, 232, 231, 0.5);
}

.action-color--white.header-action-link li a {
  color: #fff;
}

.action-color--white .offside-about {
  background: rgba(255, 255, 255, 0.05);
}

/*Action Style Color Hover*/

.action-hover-color--golden.header-action-link li a:hover {
  color: var(--main-theme-color);
}

.action-hover-color--golden.header-action-link li a span {
  background: var(--main-theme-color);
}

.action-hover-color--golden .offside-about:hover {
  background: #fff;
}

.action-hover-color--green.header-action-link li a:hover {
  color: #047b42;
}

.action-hover-color--green.header-action-link li a span {
  background: #047b42;
}

.action-hover-color--green .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--pink.header-action-link li a:hover {
  color: #ff365d;
}

.action-hover-color--pink.header-action-link li a span {
  background: #ff365d;
}

.action-hover-color--pink .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--aqua.header-action-link li a:hover {
  color: #66cec4;
}

.action-hover-color--aqua.header-action-link li a span {
  background: #66cec4;
}

.action-hover-color--aqua .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.seperate-sticky-bar {
  display: none;
}

.seperate-sticky-bar.sticky {
  display: block;
}

.inner-search {
  right: -15px;
  width: 330px;
  background: #fff;
  display: none;
}

.inner-search input[type="search"] {
  width: 100%;
  padding: 15px 50px 15px 10px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
}

.inner-search button {
  right: 0;
  background: aliceblue;
  padding: 10px 14px;
  top: 50%;
  transform: translateY(-50%);
}

.search-click {
  cursor: pointer;
}

/*****************************
* Layout - Header Mobile
*****************************/

/* .mobile-menu-logo li {
  margin-right: 15px;
}

.mobile-menu-logo li:last-child {
  margin-right: 0;
} */

.mobile-menu-logo {
  display: flex;
  align-items: center;
}

.mobile-menu-logo .logo {
  width: 100px;
  overflow: hidden;
}

.mobile-menu-logo .logo img {
  width: 100%;
  height: auto;
}

.mobile-header {
  padding: 15px 0;
}

.offside-menu {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: normal !important;
  display: block;
  border-radius: 50%;
  background: rgba(65, 136, 157, 1);
}

.offside-menu i {
  color: #fff;
}

.offside-menu-color--black {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.offside-menu-hover-color--golden:hover {
  color: var(--main-theme-color);
}

.offside-menu-hover-color--green:hover {
  color: #047b42;
}

.offside-menu-hover-color--pink:hover {
  color: #ff365d;
}

.offside-menu-hover-color--aqua:hover {
  color: #66cec4;
}

.mobile-header-bg-color--golden {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #ffffff;
}

.mobile-header-bg-color--white {
  background: #fff;
}

.mobile-header-bg-color--black {
  background: #24262b;
}

/*****************************
* Layout - Footer
*****************************/

.footer-bg {
  background: #24262b;
  padding-top: 60px;
}

@media (min-width: 768px) {
  .footer-bg {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .footer-bg {
    padding-top: 100px;
  }
}

/*Footer Top*/

.footer-top .footer-widget-single-item .title {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 23px;
  text-transform: uppercase;
}

.footer-top .footer-widget-single-item .title::after {
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.footer-top .footer-widget-single-item .footer-nav li {
  margin-bottom: 8px;
  display: block;
}

.footer-top .footer-widget-single-item .footer-nav li:last-child {
  margin-bottom: 0;
}

.footer-top .footer-widget-single-item .footer-nav li a {
  display: inline-block;
  color: #9b9b97;
  transition: all 0.5s ease;
}

.footer-top .footer-widget-single-item .footer-nav li a:hover {
  color: #fff;
  transform: translateX(10px);
}

.footer-top .footer-widget-color--golden .title::after {
  background: var(--main-theme-color);
}

.footer-top .footer-widget-color--green .title::after {
  background: #047b42;
}

.footer-top .footer-widget-color--aqua .title::after {
  background: #66cec4;
}

.footer-top .footer-widget-color--pink .title::after {
  background: #ff365d;
}

/*Footer Center*/

.footer-center {
  margin-top: 40px;
  padding: 60px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

@media (min-width: 768px) {
  .footer-center {
    margin-top: 55px;
  }
}

.footer-social .title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.footer-social-link li {
  display: inline-block;
  margin-right: 20px;
}

.footer-social-link li:last-child {
  margin-right: 0;
}

.footer-social-link li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #2f3239;
  color: #fff;
  transition: all 0.5s ease;
  font-size: 16px;
}

.footer-social-link li a:hover {
  background: #fff;
  color: #2f3239;
}

.footer-newsletter .title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.form-fild-newsletter-single-item {
  position: relative;
}

.form-fild-newsletter-single-item input {
  width: 100%;
  border: 2px solid #9b9b97;
  border-radius: 5px;
  padding: 10px 145px 10px 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease;
}

.form-fild-newsletter-single-item button {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.5s ease;
}

.form-fild-newsletter-single-item.input-color--golden input:focus {
  border-color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--golden button {
  background: var(--main-theme-color);
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--golden button:hover {
  background: #fff;
  color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--green input:focus {
  border-color: #047b42;
}

.form-fild-newsletter-single-item.input-color--green button {
  background: #047b42;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--green button:hover {
  background: #fff;
  color: #047b42;
}

.form-fild-newsletter-single-item.input-color--aqua input:focus {
  border-color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--aqua button {
  background: #66cec4;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--aqua button:hover {
  background: #fff;
  color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--pink input:focus {
  border-color: #ff365d;
}

.form-fild-newsletter-single-item.input-color--pink button {
  background: #ff365d;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--pink button:hover {
  background: #fff;
  color: #ff365d;
}

/*Footer Bottom*/

.footer-bottom {
  padding-top: 35px;
  padding-bottom: 35px;
}

.footer-copyright {
  text-transform: uppercase;
}

.footer-copyright p {
  margin-bottom: 0;
}

.footer-copyright a {
  color: #fff;
}

/*****************************
* Layout - Offcanvas
*****************************/

.offcanvas {
  z-index: 999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.5s ease;
  transform: translateX(100%);
  box-shadow: none;
  background: #fff;
  width: 300px;
  overflow-x: hidden;
}

@media (min-width: 576px) {
  .offcanvas {
    width: 450px;
  }
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 40px;
  height: 40px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.offcanvas-close:hover {
  color: red;
}

.offcanvas-title {
  margin-bottom: 40px;
}

.offcanvas-mobile-menu-section,
.offcanvas-mobile-about-section {
  background: #41889d !important;
}

.offcanvas-mobile-menu-section .offcanvas-close,
.offcanvas-mobile-about-section .offcanvas-close {
  color: #fff;
}

/*Mobile Menu Bottom Area*/

.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 7px 5px;
}

.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}

.offcanvas-menu li a.active {
  color: var(--main-theme-color);
}

/* .offcanvas-menu li a:hover {
  color: var(--main-theme-color);
} */

ul.mobile-sub-menu {
  padding-left: 30px;
}

.offcanvas-menu-expand::after {
  position: absolute;
  right: 0;
  transition: all 0.3s ease;
  color: #fff;
  /* font-family: "Font Awesome 5 Free"; */
  content: "\f0dd";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.BigWhy_ImageCarosalContainer{
  background-color: #fff;
}
.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  color: var(--main-theme-color);
  transform: rotate(90deg);
}

/*Mobile Menu Contact Info*/

.mobile-contact-info {
  text-align: center;
  margin: 0 auto;
  padding-top: 65px;
}

.mobile-contact-info .logo {
  margin: 0 auto;
  width: 200px;
}

.mobile-contact-info .logo img {
  width: 100%;
  height: auto;
}

.mobile-contact-info .address {
  margin-top: 30px;
  color: #fff;
}

.mobile-contact-info .address span {
  display: block;
}

.mobile-contact-info .social-link li {
  display: inline-block;
  margin-right: 20px;
}

.mobile-contact-info .social-link li:last-child {
  margin-right: 0;
}

.mobile-contact-info .social-link li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #2f3239;
  color: #fff;
  transition: all 0.5s ease;
  font-size: 16px;
}

.mobile-contact-info .social-link li a:hover {
  background: #fff;
  color: #2f3239;
}

.mobile-contact-info .user-link {
  margin-top: 30px;
}

.mobile-contact-info .user-link li {
  display: inline-block;
  margin-right: 15px;
}

.mobile-contact-info .user-link li:last-child {
  margin-right: 0;
}

.mobile-contact-info .user-link li a {
  color: #fff;
  text-decoration: underline;
}

.mobile-contact-info .user-link li a:hover {
  color: var(--main-theme-color);
}

.offcanvas-cart-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-cart-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-cart-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-cart-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #ededed;
  margin-right: 20px;
}

.offcanvas-cart-image {
  width: 100%;
}

.offcanvas-cart-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-cart-item-link:hover {
  color: #24262b;
}

.offcanvas-cart-total-price {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.offcanvas-cart-total-price-text {
  font-weight: 700;
}

.offcanvas-cart-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-cart-item-delete {
  color: red;
}

.offcanvas-cart-item-delete:hover {
  color: red;
}

.offcanvas-cart-action-button-link {
  display: block;
  background: #24262b;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  border: 1px border transparent;
}

.offcanvas-cart-action-button-link:hover {
  background: #fff;
  color: #24262b;
  border: 1px border #24262b !important;
}

.offcanvas-wishlist-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.home_media {
  margin: auto;
}
.offcanvas-wishlist-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-wishlist-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #ededed;
  margin-right: 20px;
}

.offcanvas-wishlist-image {
  width: 100%;
}

.offcanvas-wishlist-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-wishlist-item-link:hover {
  color: #24262b;
}

.offcanvas-wishlist-total-price-text {
  font-weight: 700;
}

.offcanvas-wishlist-action-button {
  margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-wishlist-item-delete {
  color: red;
}

.offcanvas-wishlist-item-delete:hover {
  color: red;
}

.offcanvas-wishlist-action-button-link {
  display: block;
  background: #24262b;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px border transparent;
}
.ArrowRotateArabic {
  transform: rotate(180deg);
}
.offcanvas-wishlist-action-button-link:hover {
  background: #24262b;
  color: #fff;
  border: 1px border #24262b !important;
}

.search-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(-100%, 0);
  opacity: 0;
  z-index: 99999;
}

.search-modal.open {
  transform: translate(0px, 0px);
  opacity: 1;
}

.search-modal input[type="search"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  color: white;
  background: rgba(0, 0, 0, 0);
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 10px;
  outline: none;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

.search-modal input[type="search"]:focus {
  border-color: var(--main-theme-color);
}

.search-modal .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

.search-modal .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 1;
  width: 40px;
  background: #fff;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  font-size: 0;
}

/*****************************
* Layout - Modal
*****************************/

.modal {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  padding-right: 0 !important;
}

.modal-dialog {
  max-width: calc(100% - 20px) !important;
  margin: 0 auto !important;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 730px !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 870px !important;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    min-width: 825px !important;
  }
}

.modal-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-close {
  width: 40px;
  height: 40px;
  border: 1px solid #777;
  color: #777;
  margin-bottom: 20px;
  border-radius: 3px;
}

.modal-close:hover {
  color: var(--main-theme-color);
  border-color: var(--main-theme-color);
}

@media (min-width: 768px) {
  .modal-close {
    margin-bottom: 0px;
  }
}

.modal-border {
  border-left: none;
}

@media (min-width: 992px) {
  .modal-border {
    border-left: 1px solid #ededed;
  }
}

.modal-content {
  border: none;
}

.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.modal-add-cart-product-img {
  text-align: center;
}

.modal-add-cart-product-title {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .modal-add-cart-product-title {
    margin-top: 0px;
  }
}

.modal-add-cart-product-title,
.modal-add-cart-product-price {
  display: block;
  font-weight: 500;
}

.modal-add-cart-info {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .modal-add-cart-info {
    margin-top: 0;
  }
}

.modal-add-cart-product-info,
.modal-add-cart-product-shipping-info {
  font-weight: 500;
}

.modal-add-cart-product-info li,
.modal-add-cart-product-shipping-info li {
  margin-bottom: 10px;
}

.modal-add-cart-product-info li:last-child,
.modal-add-cart-product-shipping-info li:last-child {
  margin-bottom: 0;
}

.modal-add-cart-product-info span,
.modal-add-cart-product-shipping-info span {
  font-weight: 300;
  padding-left: 5px;
}

.modal-add-cart-product-cart-buttons {
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .modal-add-cart-product-cart-buttons {
    flex-direction: row;
  }
}

.modal-add-cart-product-cart-buttons a {
  margin-top: 10px;
  margin-bottom: 10px;
  background: var(--main-theme-color);
  color: white;
  padding: 5px 10px;
  margin-right: 0px;
  text-align: center;
}

.modal-add-cart-product-cart-buttons a:last-child {
  margin-right: 0;
}

@media (min-width: 576px) {
  .modal-add-cart-product-cart-buttons a {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: 10px;
    flex-direction: row;
  }
}

.modal-add-cart-info i {
  color: var(--main-theme-color);
  padding-right: 10px;
  font-size: 16px;
}

.modal-continue-button a {
  text-decoration: underline;
  color: var(--main-theme-color);
}

.modal-continue-button a:hover {
  text-decoration: none;
}

.modal-product-details-social .title {
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
}

.modal-product-details-social ul {
  display: flex;
  flex-wrap: wrap;
}

.modal-product-details-social li {
  margin-right: 10px;
}

.modal-product-details-social li:last-child {
  margin-right: 0;
}

.modal-product-details-social li a {
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  text-align: center;
  color: #777;
  font-size: 16px;
  border: 1px solid #ededed;
}

.modal-product-about-text {
  border-bottom: 1px solid #ededed;
  margin-bottom: 10px;
}

.product-image-thumb {
  position: relative;
}

.product-image-thumb img {
  border: 1px solid #ededed;
}

.product-image-thumb.modal-product-image-thumb {
  margin-top: 15px;
}

.product-image-thumb.modal-product-image-thumb .slick-list {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.product-image-thumb.modal-product-image-thumb .slick-slide {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.product-image-thumb:hover .gallery-nav {
  visibility: visible;
  opacity: 1;
}

.material-scrolltop {
  z-index: 999;
  display: block;
  position: fixed;
  width: 0;
  height: 0;
  bottom: 23px;
  right: 23px;
  padding: 0;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  cursor: hand;
  border-radius: 50%;
  background: var(--main-theme-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0, 1);
}

.material-scrolltop:hover {
  background-color: var(--main-theme-color);
  text-decoration: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5), 0 3px 15px rgba(0, 0, 0, 0.5);
}

.material-scrolltop::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 0;
  border-radius: 100%;
  background: #bda379;
}

.material-scrolltop:active::before {
  width: 120%;
  padding-top: 120%;
  transition: all 0.2s ease-out;
}

.material-scrolltop.reveal {
  width: 56px;
  height: 56px;
}

.material-scrolltop span {
  display: block;
  font-size: 25px;
  color: #fff;
}

.offcanvas-cart-action-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address img {
  padding-bottom: 20px;
}

.mobile-contact-info .social-link {
  padding-top: 5px;
}

/* --Fashion--- */

/* -------------------------
   Banner Area 
---------------------------*/

.banner_text_one h1 {
  font-size: 150px;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 150px;
}

.banner_text_one h3 {
  text-transform: uppercase;
  font-weight: 500;
  color: #f3f3f3;
}

.banner_text_one a {
  margin-top: 30px;
}

.banner_text_one h1 span {
  display: block;
  color: #fff;
  font-weight: 700;
}

.hero_img img {
  position: absolute;
  top: -163px;
  right: 0;
}

/* -------------------------
   Product variation Area 
---------------------------*/

.product_variation_one_boxed {
  margin-bottom: 30px;
}

.row .col-lg-4 .product_variation_one_boxed:last-child {
  margin-bottom: 0;
}

.product_variation_one_boxed {
  position: relative;
  overflow: hidden;
}

.product_variation_one_boxed img {
  width: 100%;
}

.product_var_one_text_center {
  position: absolute;
  left: 40px;
  bottom: 0;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.product_var_one_text {
  position: absolute;
  left: 40px;
  top: 50%;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.product_var_one_text h2,
.product_var_one_text_center h2 {
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.product_var_one_text h4,
.product_var_one_text_center h4 {
  text-transform: uppercase;
}

.product_var_one_text a,
.product_var_one_text_center a {
  margin-top: 20px;
}

.color_one {
  color: var(--main-theme-color);
}

/* -------------------------
    Hot Product Area 
---------------------------*/

/*  Filers CSS */
.tabs_center_button .nav-tabs {
  border-bottom: none;
  width: 100%;
  justify-content: center;
  margin-left: 10px;
}

.tabs_center_button .nav-tabs li a {
  transition: inherit;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  background: transparent;
  padding-bottom: 5px;
}

.tabs_center_button .nav-tabs li:first-child a {
  margin-left: 0px;
}

.tabs_center_button .nav-tabs li a:hover {
  color: var(--main-theme-color);
}

.tabs_center_button .nav-tabs li .active {
  color: var(--main-theme-color) !important;
  border-bottom: 2px solid var(--main-theme-color);
}

/* -------------------------
     To Days Area 
---------------------------*/

.product_wrappers_one {
  overflow: hidden;
}

.product_wrappers_one .thumb {
  position: relative;
  overflow: hidden;
}
.product_wrappers_one .thumb .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.product_wrappers_one .thumb .image img {
  z-index: 1;
  max-width: 100%;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.product_wrappers_one .thumb .image img.hover-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
}

.product_wrappers_one .thumb .badges {
  position: absolute;
  z-index: 8;
  top: 18px;
  left: 18px;
  display: flex;
  flex-direction: column;
}

.product_wrappers_one .thumb .badges span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  padding: 0 7px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 3px;
  color: #fff;
}

.product_wrappers_one .thumb .badges span + span {
  margin-top: 10px;
}

.product_wrappers_one .thumb .badges span.new {
  background-color: #41889d;
}

.product_wrappers_one .thumb .badges span.hot {
  background-color: #41889d;
}

.product_wrappers_one .thumb .badges span.sale {
  background-color: #41889d;
}

.product_wrappers_one .thumb .actions {
  position: absolute;
  z-index: 9;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
}

.product_wrappers_one .thumb .actions .action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #212121;
  border-radius: 50%;
  background-color: #fff;
}

.product_wrappers_one .thumb .actions .action i {
  font-size: 14px;
  line-height: 1;
}

.product_wrappers_one .thumb .actions .action + .action {
  margin-top: 10px;
}

.product_wrappers_one .thumb .actions .action:not(.wishlist) {
  visibility: hidden;
  transform: translateY(20px);
  opacity: 0;
}

.product_wrappers_one .thumb .actions .action.active {
  color: #3e667b;
}

.product_wrappers_one .thumb .actions .action:hover:not(.active) {
  color: #fff;
  background-color: #3e667b;
}

.product_wrappers_one .thumb .add-to-cart {
  display: block;
  width: 80%;
  height: 45px;
  background-color: #fff;
  text-align: center;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  position: absolute;
  display: flex;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 11;
  margin: auto;
  font-weight: 600;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transform: translateY(20px);
  color: #3f3f3f;
  opacity: 0;
  font-family: Raleway, sans-serif;
}
.product_wrappers_one .thumb .add-to-cart:hover {
  color: #3e667b;
}

.product_wrappers_one .content {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 23px 15px;
  transition: all 0.3s ease 0s;
  text-align: center;
  background-color: #fff;
}

.product_wrappers_one .content .title {
  font-size: 14px;
  margin: 0px 0 10px;
}

.product_wrappers_one .content .title a {
  text-decoration: none;
  color: #1d1d1d;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  line-height: 1.5;
  display: block;
  font-size: 16px;
}

.product_wrappers_one .content .price {
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
}

.product_wrappers_one .content .price:not(:last-child) {
  margin-bottom: 20px;
}

.product_wrappers_one .content .price span.new {
  color: #3f3f3f;
  font-weight: 500;
}

.product_wrappers_one .content .price span.old {
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999;
}

.product_wrappers_one .content .btn {
  align-self: center;
}

.product_wrappers_one:hover .thumb .image img {
  transform: scale(1.1) rotate(3deg);
}

.product_wrappers_one:hover .thumb .image img:not(:last-child) {
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.product_wrappers_one:hover .thumb .image img.hover-image {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}

.product_wrappers_one:hover .thumb .actions .action {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.product_wrappers_one:hover .thumb .add-to-cart {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.product_wrappers_one:hover .content .title a {
  color: #474747;
}

.product_wrappers_one:hover .content .title a:hover {
  color: #3e667b;
}

/* -------------------------
  Instagram Area Start 
---------------------------*/

.instgram_post {
  position: relative;
}

.instgram_post > a {
  display: block;
  position: relative;
  z-index: 1;
}

.instgram_post > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #41889d;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.instgram_post > a i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(1.2) translate(-50%, -50%);
  z-index: 2;
  font-size: 40px;
  color: #262626;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}

.instgram_post > a:hover::before {
  opacity: 0.7;
}

.instgram_post > a:hover i {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.instgram_post img {
  width: 100%;
}

.instagram_post_slider {
  margin-bottom: -7px;
}

/* -------------------------
  Footer Area Start 
---------------------------*/

#footer_one {
  padding: 70px 0;
  background-color: #384757;
}
#footer_one .rowspacingDiv {
  margin: 0 1rem;
}

@media (max-width: 991px) {
  #footer_one .rowspacingDiv {
    margin: 0;
  }
}

#footer_one .footer_left_side p {
  padding-top: 15px;
}

#footer_one .footer_left_side h2 {
  padding-top: 15px;
}

#footer_one .footer_one_widget li {
  font-style: italic;
  font-weight: 300;
}

#footer_one .footer_one_widget ul {
  padding-top: 15px;
}

#footer_one .footer_one_widget h3 {
  font-size: 18px;
  color: #fff;
}

#footer_one .footer_one_widget ul li {
  padding-top: 10px;
  color: #fff;
}

#footer_one .footer_one_widget ul li a {
  color: #fff;
}

#footer_one .footer_one_widget ul li a:hover {
  color: #41889d;
}

#footer_one .footer_one_widget ul li:first-child {
  padding-top: 0px;
}
#footer_one .footer_one_widget p {
  font-size: 14px;
  color: #fff;
}

#footer_one .footer_left_side_icon {
  text-align: center;
  padding-top: 20px;
}
#footer_one .footer_left_side_icon ul {
  margin: auto;
}

#footer_one .footer_left_side_icon ul li {
  display: inline-flex;
  padding-left: 10px;
}

#footer_one .footer_left_side_icon ul li:first-child {
  padding-left: 0px;
}

#footer_one .footer_left_side_icon ul li a i {
  font-size: 18px;
}
.middle_footer_color ul li a i {
  color: white;
  transition: 0.3s ease-in-out;
}
.middle_footer_color ul li a i:hover {
  color: #41889d;
}

#footer_one .footer_left_side p {
  /* padding-right: 50px; */
  color: #fff;
}

#mc_embed_signup {
  padding-top: 20px;
}

#mc_embed_signup button {
  /* margin-top: 20px; */
  background: #41889d;
  border: 1px solid #41889d;
  border-radius: 3px;
  height: 45px;
  margin-left: 10px;
  color: #fff;
}

#mc_embed_signup button:hover {
  background-color: #fff;
  color: #41889d;
}

#mc_embed_signup .mc-form {
  display: flex;
  align-items: center;
}
#mc_embed_signup .mc-form button {
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  padding-right: 20px;
}

/* --CopyRight Area-- */

#copyright_one {
  padding: 15px 0;
  border-top: 1px solid #ededed;
  background: #384757;
}
.lowerFooterSection {
  border-top: 1px solid #ededed;
}

.copyright_left h6 {
  font-size: 14px;
  color: #fff;
}

#copyright_one .copyright_right {
  text-align: right;
}

#copyright_one .copyright_left h6 span {
  font-weight: 600;
  color: var(--main-theme-color);
}
/* -------------------------
  Footer Area End 
---------------------------*/

/* ------------------------------
    Furniture Home Page
--------------------------------- */
/* -------------------------
     Banner Area
---------------------------*/
.bg_1 {
  /* background-image: url("../img/furniture/banner/banner1.png"); */
}

.bg_2 {
  /* background-image: url("../img/furniture/banner/banner2.png"); */
}

.bg_3 {
  /* background-image: url("../img/furniture/banner/banner3.png"); */
}

.background_bg {
  background-position: center center;
  background-size: cover;
}

.furniture_slider_box {
  overflow: hidden;
}

/* .furniture_slider {
  padding: 20px 0;
} */

.furniture_slider .slider_cat_name {
  /* height: 100%; */
  /* align-items: center;
  display: flex;
  justify-content: center; */
  position: relative;
  right: 0;
}
.languageSelect{
  -webkit-appearance: none;

}
.furniture_slider .slider_cat_name h2 {
  font-family: "Ogg-Light";
  /* text-align: center; */
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  /* line-height: 120%; */
  /* color: #B56B1F; */
  background: linear-gradient(
    to bottom,
    #b56b1f,
    #b56b1fd4,
    #b56b1fba,
    #b56b1f96
  );
  -webkit-background-clip: text;
  color: transparent;
  /* position: relative; */
  text-transform: uppercase;
  height: max-content;
}



#furniture_banner .slick-dots {
  bottom: 2rem;
  width: 25%;
  left: 60%;
}
#about-top  .slick-dots {
  bottom: -2.5rem;
}
#about-top .slick-dots li button:before, #service_promo_area .slick-dots li button:before, #packaging_list .slick-dots li button:before{
  font-size: 14px;
  color: #b56b1f;
}

#about-top .slick-dots li.slick-active button:before, #service_promo_area .slick-dots li.slick-active button:before,
#packaging_list slick-dots li.slick-active button:before{
  font-size: 16px;
  color: #b56b1f;
  opacity: 0.65;
}

#about-top .slick-dots li button:hover:before,
#about-top .slick-dots li button:focus:before,
#service_promo_area .slick-dots li button:hover:before,
#service_promo_area .slick-dots li button:focus:before,
#packaging_list .slick-dots li button:hover:before,
#packaging_list .slick-dots li button:focus:before {
  font-size: 16px;
  color: #b56b1f;
  opacity: 0.65;
}

#furniture_banner .slick-dots li button:before {
  color: #fff;
  font-size: 14px;
  opacity: 1;
}

#furniture_banner .slick-dots li.slick-active button:before {
  border: 1px solid #ffffff;
  border-radius: 50%;
}

#furniture_banner .slick-dots li button:hover:before,
#furniture_banner .slick-dots li button:focus:before {
  border: 1px solid #ffffff;
  border-radius: 50%;
}

/* .furniture_slider .slider_cat_name h2:before {
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    width: 1.2em;
    overflow: hidden;
    color: #ccc;
} */

.furniture_slider_content h5 {
  opacity: 0;
  -webkit-transition: all 1200ms ease;
  -o-transition: all 1200ms ease;
  transition: all 1200ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content h2 {
  padding: 35px 0;
  font-size: 36px;
  font-weight: 300;
  opacity: 0;
  -webkit-transition: all 1400ms ease;
  -o-transition: all 1400ms ease;
  transition: all 1400ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  color: #41889d;
  line-height: normal;
}

.furniture_slider_content a {
  opacity: 0;
  -webkit-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content p,
.furniture_slider_content ul {
  padding-bottom: 30px;
  opacity: 0;
  -webkit-transition: all 1500ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  color: #000;
  font-size: 18px;
}

.furniture_slider_content ul {
  padding-left: 20px;
}

.furniture_slider_content ul li {
  list-style-type: disc;
}

#furniture_banner .slick-active .furniture_slider_content h5 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
  background: linear-gradient(
    to bottom,
    #b56b1f,
    #b56b1fd4,
    #b56b1fba,
    #b56b1f96
  );
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  margin-left: 45px;
}

#furniture_banner
  .slick-active
  .furniture_slider_content
  .EngBannerHeader:before {
  /* position: absolute;
    content: " _ ";
    left: -20px;
    font-size: 34px;
    width: 2rem;
    height: 3px;
    color: #B56B1F;
    line-height: 0;
    top: -3px; */

  position: absolute;
  content: "";
  height: 2px;
  left: -45px;
  right: 0;
  width: 5%;
  background: #b56b1f;
  top: 10px;
}

#furniture_banner .slick-active .furniture_slider_content h2 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

#furniture_banner .slick-active .furniture_slider_content p,
#furniture_banner .slick-active .furniture_slider_content ul {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
  font-weight: 300;
}

#furniture_banner .slick-active .furniture_slider_content a {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1700ms;
  -o-transition-delay: 1700ms;
  transition-delay: 1700ms;
  font-size: 14px;
  font-weight: 500;
}

#furniture_banner .owl-theme .owl-dots {
  position: absolute;
  top: 0;
  right: 50px;
  bottom: 0;
  margin: 0;
  width: 50px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot {
  position: relative;
  display: block;
  width: 30px;
  height: 5px;
  background: var(--main-theme-color);
  border: 0px solid;
  margin: 5px 0px;
  padding: 0px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot span {
  display: none;
}

#furniture_banner .owl-theme .owl-dots .owl-dot.active {
  width: 40px;
}

#furniture_banner .owl-theme .owl-dots .owl-dot:before {
  position: absolute;
  top: 0px;
  right: -10px;
  bottom: 0px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background: var(--main-theme-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* -------------------------
     Banner Bottom Area
---------------------------*/
.furniture_bottom_wrapper {
  position: relative;
}

.furniture_bottom_wrapper .hover_effect_furniture {
  position: relative;
  display: block;
  outline: none !important;
}

.furniture_bottom_wrapper .hover_effect_furniture::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper:hover > .hover_effect_furniture:before {
  visibility: visible;
  opacity: 1;
}

.furniture_bottom_wrapper .hover_effect_furniture::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper a img {
  width: 100%;
}

.furniture_bottom_content {
  display: inline-block;
  position: absolute;
  padding-top: 0.4rem;
  left: 2rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.furniture_bottom_content a {
  color: #fff;
  border-bottom: 2px solid var(--main-theme-color);
}

.furniture_bottom_content a:hover {
  color: var(--main-theme-color);
}

.furniture-content-lg h5 {
  color: #fff;
}

.furniture-content-lg h2 {
  color: #fff;
  padding: 10px 0;
  line-height: 50px;
}

.furniture-content-md h5 {
  color: #fff;
  line-height: 0;
  padding-bottom: 2px;
  font-size: 16px;
}

.furniture-content-md h2 {
  color: #fff;
  padding: 13px 0;
  line-height: 28px;
  font-size: 22px;
}

/* -------------------------
  Furniture Popular Product
---------------------------*/
#furniture_popular_product .product_box {
  margin: 0px 15px 15px 15px;
}

/* -------------------------
  Our Story Area
---------------------------*/
#furniture_story {
  /* background-image: url("../img/furniture/common/parallex.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0;
  position: relative;
}

#furniture_story::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  position: absolute;
  top: 0;
}

.our_story_content {
  text-align: center;
  z-index: 9;
  position: relative;
}

.our_story_content p {
  padding: 40px 0;
}

.our_story_content h2,
.our_story_content p {
  color: #fff;
}

/* -------------------------
     Blog Post Area
---------------------------*/

section#blog_grid .blog_text {
  position: unset;
  border-radius: 0;
  box-shadow: unset;
}

section#blog_grid .blog_one_item {
  margin-bottom: 0;
}

.blog_post_wrapper {
  position: relative;
  overflow: hidden;
}

.blog_post_img {
  position: relative;
}

.blog_post_img a img {
  width: 100%;
  transition: 0.4s all ease-in-out;
}

.blog_post_wrapper:hover img {
  transform: rotate(2deg) scale(1.1);
}

.blog_post_date {
  text-align: center;
  padding: 15px 90px 15px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 76% 0, 100% 100%, 0 100%);
  background: #222;
  color: #fff;
  font-size: 18px;
}

.blog_post_date span {
  font-size: 16px;
}

.right_block {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 370px;
  background: rgba(34, 34, 34, 0.9);
  height: 100%;
  padding: 30px;
}

.right_side_content {
  color: #fff;
}

.right_side_content h5 {
  color: #fff;
  font-size: 24px;
}

.right_side_content p {
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 25px;
  font-size: 14px;
  padding-top: 15px;
}

.right_side_content a {
  color: #fff;
  border-bottom: 1px solid var(--main-theme-color);
}

.right_side_content a:hover {
  color: var(--main-theme-color);
}

.icon_promo_item {
  display: inline-table;
  margin-right: 40px;
}

.icon_promo_item i {
  background: #525252;
  color: #fff;
  text-align: center;
  line-height: 84px;
  font-size: 22px;
  width: 84px;
  height: 84px;
  border-radius: 50%;
}

.icon_promo_item p {
  max-width: 129px;
  margin-bottom: 0;
  margin-top: 18px;
  display: block;
  font-weight: 700;
  text-align: left;
}

.promotion_img {
  text-align: right;
}

.insta_heading_elce {
  padding-bottom: 30px;
}

/* -------------------------
 product_slider_one Modal   
---------------------------*/

#product_slider_one .owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  background: var(--heading-color);
}

#product_slider_one .owl-theme .owl-dots .owl-dot.active span,
#product_slider_one.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color);
  width: 20px;
  height: 6px;
}

.product_one_modal_top .close_modal_icon {
  position: absolute;
  right: 0px;
  z-index: 10;
  top: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  border-radius: 50%;
  transition: 0.3s;
  display: inline-block;
  background: #fff;
  border: 0;
}

.product_one_modal_top .close_modal_icon:hover {
  background: var(--main-theme-color);
  color: #fff;
  transform: rotate(90deg);
}

.modal_product_content_one .reviews_rating {
  padding-top: 10px;
}

.modal_product_content_one .reviews_rating i {
  color: var(--main-theme-color);
}

.modal_product_content_one .reviews_rating span {
  padding-top: 4px;
}

/* product inner page style */

.product-detail-general-style p {
  padding-top: 0px;
  margin-bottom: 20px;
}

#product_count_form_one {
  padding-top: 20px;
}

#product_count_form_one input {
  width: 20%;
  min-height: 40px;
  border-radius: 0;
  margin: 0 8px;
  text-align: center;
}

/* #product_count_form_two {
  padding-top: 20px;
} */
.ActivePath {
  outline: 1px solid var(--main-theme-color);
}
.ActivePathMobile {
  outline: 1px solid #fff;
  width: 35%;
}
.testClass {
  position: relative;
  right: 15%;
}
.table_responsive {
  max-width: 100vw;
}

.tdVewOrderAR td {
  float: right;
}
.tdVewOrderAR .price {
  float: left !important;
}
#product_count_form_two input {
  width: inherit;
  min-height: 40px;
  border-radius: 0;
  margin: 0 8px;
  text-align: center;
}

#Gift_card_Form {
  padding-top: 20px;
}

#Gift_card_Form input {
  min-height: 40px;
  width: 50%;
  border-radius: 4px;
}
.GIft-One {
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}
.Gift-Input {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
}
.product_count_oneArabic a {
  margin-top: 60px;
  margin-right: -9.1rem;
}
.product_count_one a {
  margin-right: 29px;
  margin-top: 30px;
}

.variable-single-item {
  margin-top: 15px;
}

.variable-single-item > span {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: capitalize;
  color: #24262b;
}

.product-variable-color label {
  line-height: 0;
  margin-right: 5px;
  position: relative;
}

.product-variable-color label input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.product-variable-color label span {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: #e9ecef;
  border-radius: 2px;
  cursor: pointer;
}

.product-variable-color label .product-color-red {
  background: red;
}

.product-variable-color label .product-color-tomato {
  background: tomato;
}

.product-variable-color label .product-color-green {
  background: green;
}

.product-variable-color label .product-color-light-green {
  background: #90ee90;
}

.product-variable-color label .product-color-blue {
  background: #00f;
}

.product-variable-color label .product-color-light-blue {
  background: #add8e6;
}

.product-variable-color label span::after {
  position: absolute;
  color: #fff;
  content: "";
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.product-variable-color label input:checked ~ span::after {
  /* font-family: FontAwesome; */
  content: "\f00c";
}

.modal_share_icons_one {
  padding-top: 15px;
}
.shareMediaMediaIcon {
  background-color: #fff;
  z-index: 9999;
}

.posted_icons_one {
  padding-top: 10px;
}

.posted_icons_one button {
  padding-right: 6px !important;
  transition: all 0.4s;
}

.posted_icons_one button:hover {
  transform: scale(1.2);
}

.modal_share_icons_one h4 {
  font-size: 18px;
  font-weight: 500;
}

.posted_icons_one a svg {
  width: 16px;
  height: 16px;
}

.posted_icons_one a {
  border: 1px solid var(--main-theme-color);
  margin-right: 17px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}

.plus-minus-input {
  display: flex;
  align-items: center;
  width: 9rem;
}
.plus-minus-input input {
  text-align: center;
}

.plus-minus-input input::-webkit-outer-spin-button,
.plus-minus-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plus-minus-input input[type="number"] {
  -moz-appearance: textfield;
}

.plus-minus-input button {
  background: #41889d;
  color: #fff;
  font-size: 30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 0px;
  position: relative;
}

.border-rad {
  border-radius: 8px;
}

.plus-minus-input button i {
  font-size: 13px;
}

.plus-minus-input svg {
  width: 16px;
  height: 18px;
  position: absolute;
  left: 21%;
  top: 4px;
}

/* media section */

#medialist_area .slick-dots li button:before {
  color: rgb(212, 212, 212);
  font-size: 17px;
  opacity: 1;
}

#medialist_area .slick-dots li.slick-active button:before {
  color: #b56b1f;
  opacity: 0.65;
}

#medialist_area .slick-dots li button:hover:before,
#medialist_area .slick-dots li button:focus:before {
  color: #b56b1f;
  opacity: 0.65;
}

/* ---------------------------------
       Shop Page 
-------------------------------------*/

.customs_sel_box {
  width: 200px;
  padding: 12px;
  font-size: 16px;
  line-height: 1;
  height: 45px;
  background: url(../img/common/arrow.png) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 170px;
  border: 1px solid;
  background-size: 6%;
}

#to_days_area {
  background: linear-gradient(90deg, #62afb9 0%, #89cac2 52.5%, #4d93a3 100%);
  position: relative;
}

#to_days_area:before {
  position: absolute;
  content: "";
  background-image: url("../img/common/greygif.png");
  width: 578px;
  height: 666px;
  top: -5%;
  left: -5%;
}

#to_days_area .product_wrappers_one {
  margin: 0 15px 15px 15px;
}

.product_wrappers_one {
  margin-top: 30px;
  box-shadow: 0px 3px 15px 0px #0000000f;
}

.panel > *:first-child,
.panel > *:last-child {
  transition-delay: 0.8s;
}
.product_shot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.product_shot_title p {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  text-transform: uppercase;
  padding-right: 20px;
}

.product_shot_view ul {
  display: flex;
}

.product_shot_view ul li {
  padding-left: 10px;
}

.product_shot_view ul li:first-child {
  padding-left: 20px;
}

.product_shot_view ul li i {
  font-size: 20px;
}

.pagination {
  padding: 0;
  display: block;
  margin: 40px 0 100px;
  text-align: center;
}

.pagination .page-item {
  display: inline-block;
  margin: 0 3px;
}

.pagination .page-item .page-link {
  color: var(--heading-color);
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pagination .page-item.active .page-link {
  background-color: var(--main-theme-color);
  color: #ffffff;
  -webkit-box-shadow: 0px 7px 20px 0px #dcdcdc;
  box-shadow: 0px 7px 20px 0px #dcdcdc;
}

.product_shot_view .active {
  color: var(--main-theme-color);
}

/* ---------------------------------
       Shop List Page 
-------------------------------------*/

.product_wrappers_list {
  background: #ffffff;
  padding: 40px 40px;
  box-shadow: 2px 2px 15px 2px #0000000a;
  margin-bottom: 30px;
}

#shop_main_area
  .row:nth-child(2)
  .col-lg-12:first-child
  .product_wrappers_list {
  margin-top: 30px;
}

.product_wrappers_list .content .product_para_top p {
  padding-top: 5px;
}

.product_wrappers_list .content .product_para_top .price {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-theme-color);
  display: block;
  padding-top: 10px;
}

.product_wrappers_list .actions {
  padding-top: 10px;
}

.product_wrappers_list .actions a {
  padding-right: 10px;
  font-size: 18px;
}

.product_wrappers_list .content a {
  margin-top: 20px;
}
.viewMore_mobile {
  cursor: pointer;
}
/* ---------------------------------
       Shop Sidebar Page 
-------------------------------------*/

.custom_boxed {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_boxed:last-child {
  margin-bottom: 0;
}

.custom_boxed input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
.checkmarkarabic {
  position: absolute;
  top: 0;
  right: 25%;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.custom_boxed:hover input ~ .checkmark {
  background-color: var(--main-theme-color);
}

.custom_boxed input:checked ~ .checkmark {
  background-color: var(--main-theme-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom_boxed input:checked ~ .checkmark:after {
  display: block;
}

.custom_boxed .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.shop_sidebar_wrapper {
  padding-top: 30px;
}

.shop_Search {
  position: relative;
}

.shop_Search form input {
  padding: 10px 46px 10px 17px;
  border-radius: 0;
}

.shop_Search form button {
  background: none;
  position: absolute;
  top: 8px;
  right: 15px;
}

.shop_Search form button img {
  width: 20px;
}

.shop_sidebar_boxed {
  padding-top: 30px;
}

.shop_sidebar_boxed h4 {
  margin-bottom: 20px;
  font-size: 18px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
}

.price_filter
  .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
  background: #312620 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  height: 6px;
  margin-bottom: 20px;
  margin-left: auto;
  border-radius: 3px;
}

.price_filter .ui-slider-range {
  background: #312620 none repeat scroll 0 0;
  border-radius: 2px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
  height: 20px;
  width: 20px;
  top: 0;
  margin-top: -7px;
  background: #fff;
  border-radius: 50%;
  margin-left: 0;
  border: 3px solid #312620;
  transition: 0.1s linear;
  display: block;
}

.price_filter .range_count {
  display: flex;
  justify-content: space-between;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:focus {
  outline: none;
  box-shadow: none;
}

.price_slider_amount {
  overflow: hidden;
}

.price_slider_amount > span {
  font-size: 14px;
  font-weight: 500;
  color: #544842;
  text-transform: capitalize;
  margin-right: 5px;
}

.price_slider_amount > input[type="text"] {
  height: auto;
  margin-left: 0;
  text-align: left;
  width: 100px;
  border: none;
  font-size: 14px;
  color: #544842;
  font-weight: 500;
}

.price_slider_amount > input[type="submit"] {
  float: left;
  text-transform: uppercase;
  transition: all 400ms ease-out 0s;
  cursor: pointer;
  width: 98px;
  padding: 11px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.price_filter {
  margin-top: 30px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:hover {
  border-color: #ff6000;
}

#sizes_input .custom_boxed {
  padding-left: 25px;
  margin-bottom: 0px;
  margin-right: 20px;
  display: inline-block;
}

.skillbar {
  margin-bottom: 40px !important;
}

/* ---------------------------------
      Product Single Page 
-------------------------------------*/
.slider-nav .nav_img {
  margin-left: 15px;
}

.product_img_two_slider {
  margin-bottom: 30px;
}

.product_img_two_slider img {
  width: 100%;
}

.slider-nav .nav_img:first-child {
  margin-left: 0;
}

#product_single_one .slick-dots li.slick-active button:before {
  color: #ffffff;
}

#product_single_one .slick-dots li button:before {
  font-size: 16px;
  width: 20px;
  height: 20px;
  color: #d9d9d9;
}

#product_single_one .slick-dots {
  bottom: 17px;
}

#product_single_one .customs_selects {
  padding-top: 30px;
}

#product_single_one .variable-single-item {
  padding-top: 10px;
}

#product_single_one .reviews_rating {
  padding-top: 15px;
}

#product_single_one span.new {
  background-color: #41889d;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 20px;
  display: inline-block;
}

#product_single_one h3 {
  position: relative;
  margin-bottom: 30px;
}

#product_single_one h3:after {
  position: absolute;
  content: "";
  height: 1px;
  left: 0;
  right: 0;
  width: 100%;
  background: #000;
  margin: 0 auto;
  bottom: -1rem;
}

#product_single_one .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_one .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_one .modal_product_content_one p {
  color: #131d3b;
  margin-bottom: 15px;
  padding-top: 4px;
}

#product_single_one .modal_product_content_one p ul {
  list-style: disc !important;
  padding-left: 30px;
  margin-bottom: 1.5rem;
  padding-right: 30px;
}

#product_single_one .modal_product_content_one p ul li {
  list-style: disc;
}

#product_single_one .modal_product_content_one > ul {
  padding: 15px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

#product_single_one .modal_product_content_one > ul li {
  display: inline-block;
  width: calc(100% / 2);
}

#product_single_one .modal_product_content_one > ul li:before {
  content: "• ";
  font-size: 25px;
}

#product_single_one .links_Product_areas {
  padding-top: 25px;
}

#product_single_one .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_one .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_one .links_Product_areas .theme-btn-one {
  margin-top: 30px;
}

/* .product_single_one_img img {
    width: 100%;
} */

.product_details_tabs {
  padding-top: 60px;
}

.product_details_tabs .nav-tabs {
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
}

.productTabTitle li {
  padding-right: 30px;
}

.product_details_tabs .nav-tabs li a {
  font-size: 18px;
  font-weight: 500;
  color: #afafaf;
}

.product_details_tabs ul li a.active {
  color: var(--main-theme-color);
}

.row.product_instr_row {
  background: #f1f1f1;
  padding: 30px;
}

.product_instr {
  display: flex;
  align-items: center;
}

.product_instr img {
  padding-right: 15px;
}

.product_instr p {
  color: #000;
}

#composition .product_composition,
#methodofuse .product_use,
#benefits .product_benefit,
#description .product_description {
  padding-top: 20px;
}

#composition .product_composition h3,
#methodofuse .product_use h3,
#benefits .product_benefit h3,
#description .product_description h3 {
  position: unset;
  margin-bottom: 20px;
  font-size: 20px;
}

#composition .product_composition h3::after,
#methodofuse .product_use h3::after,
#benefits .product_benefit h3::after,
#description .product_description h3::after {
  display: none;
}
#composition .product_composition p,
#methodofuse .product_use p,
#benefits .product_benefit p,
#description .product_description p {
  color: #000;
  padding-bottom: 10px;
}

#composition .product_composition p:last-child,
#methodofuse .product_use p:last-child,
#benefits .product_benefit p:last-child,
#description .product_description p:last-child {
  padding-bottom: 0px;
}

#composition .product_composition p strong,
#methodofuse .product_use p strong,
#benefits .product_benefit p strong,
#description .product_description p strong {
  font-weight: 500;
}

#composition .product_composition ul,
#methodofuse .product_use ul,
#benefits .product_benefit ul,
#description .product_description ul {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#composition .product_composition ul li,
#methodofuse .product_use ul li,
#benefits .product_benefit ul li,
#description .product_description ul li {
  list-style: disc;
  padding-top: 10px;
}

#composition .product_composition ul li:first-child,
#methodofuse .product_use ul li:first-child,
#benefits .product_benefit ul li:first-child,
#description .product_description ul li:first-child {
  padding-top: 0px;
}

#composition .product_composition ul li:last-child,
#methodofuse .product_use ul li:last-child,
#benefits .product_benefit ul li:last-child,
#description .product_description ul li:last-child {
  padding-bottom: 10px;
}

#review .product_reviews {
  padding-top: 20px;
}

#review .product_reviews .media-public ul li {
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
}

#review .product_reviews .media-img {
  padding-right: 20px;
}

#review .product_reviews .media .media-img img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

#review .product_reviews .media-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#review .product_reviews ul .media {
  box-shadow: 0px 0px 15px 6px #96969614;
  padding: 44px 50px;
  margin-top: 30px;
}

#review .product_reviews ul .media:first-child {
  margin-top: 0;
}

#review .product_reviews .media-header .media-name h4 {
  font-size: 20px;
  padding-bottom: 10px;
}

#review .product_reviews .media-header .post-share a {
  font-size: 16px;
  color: #848484;
  padding-left: 18px;
}

#review .product_reviews .media-header .post-share a:hover {
  color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph p {
  padding-top: 5px;
}

#review .product_reviews .media-pragraph .product_review_strat span i {
  color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph {
  padding-top: 5px;
}

.why_esme p {
  padding-bottom: 10px;
}
.why_esme p:last-child {
  padding-bottom: 0px;
}

/* ---------------------------------
      Product Single Two Page 
-------------------------------------*/

#product_single_two .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 44%;
}

#product_single_two .owl-prev {
  background: black;
  width: 25px;
  height: 35px;
  color: #fff;
}

#product_single_two .owl-next {
  background: black;
  width: 25px;
  height: 35px;
  color: #fff;
}

#product_single_two .owl-prev:hover,
#product_single_two .owl-next:hover {
  background: var(--main-theme-color);
}

#product_single_two .customs_selects {
  padding-top: 30px;
}

#product_single_two .variable-single-item {
  padding-top: 10px;
}

#product_single_two .reviews_rating {
  padding-top: 15px;
}

#product_single_two .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_two .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_two .modal_product_content_one p {
  padding-top: 20px;
}

#product_single_two .links_Product_areas {
  padding-top: 30px;
}

#product_single_two .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_two .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_two .links_Product_areas .theme-btn-one {
  margin-top: 20px;
}

/* ---------------------------------
     About Page 
-------------------------------------*/

.about_top_img img {
  width: 100%;
}

.about_top_left_content h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  background: linear-gradient(
    to left,
    #b56b1fd4,
    #b56b1fba,
    #b56b1f96,
    #b56b1f
  );
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  margin-bottom: 20px;
}

.about_top_left_content h3 {
  color: #414141;
  margin-bottom: 30px;
}

.about_top_left_content h4 {
  line-height: 30px;
  padding-top: 15px;
  font-weight: 400;
}

.about_top_left_content p {
  margin-bottom: 30px;
  font-size: 15px;
}

#about_progressbar {
  background: #f6f6f6;
}

.about_progressbar_content p {
  padding-top: 20px;
}

.skill-progressbar {
  margin-bottom: 35px;
}

.skill-progressbar:last-child {
  margin-bottom: 0;
}
#service_promo_area {
  margin-bottom: 20px;
}
.service_promo_single_item {
  text-align: center;
  box-shadow: 0px 0px 6px 1px #0000001c;
  padding: 25px 10px;
  height: 100%;
}
.service_prom_image .IconService {
  width: 100px;
}
.service_prom_content {
  padding-top: 10px;
}
.service_prom_content h3 {
  font-size: 19px;
}

.service_prom_content p {
  padding-top: 10px;
}

#team_area {
  background-color: #f6f6f6;
}

.team-single {
  background: #fff;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
}

.team-content {
  position: relative;
  font-size: 16px;
  padding-top: 20px;
}

.team-name {
  font-weight: 700;
  font-size: 20px;
}

.team-img img {
  margin: 0 auto;
}

.team-social {
  width: 100%;
  background: #fff;
  bottom: -22px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
  padding: 10px 0;
}

.team-social li {
  display: inline-block;
  margin-right: 10px;
}

.team-social li:last-child {
  margin-right: 0;
}

.team-social a {
  color: #000;
  transition: all 0.5s ease;
}

.team-social a:hover {
  color: #b19361;
}

.team-single:hover .team-social {
  bottom: -24px;
  opacity: 1;
  visibility: visible;
}

.pos-absolute {
  position: absolute;
}

/* ---------------------------------
     Cart Page 
-------------------------------------*/

/*****************************
* Components - Table
*****************************/

table {
  width: 100%;
}

.table-responsive {
  position: relative;
  z-index: 1;
}

.view {
  color: #b19361;
}

.table_desc {
  border: 1px solid #ededed;
}

.table_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.table-responsive table thead {
  background: var(--main-theme-color);
}

.table_page table thead tr th {
  color: #fff;
  border-bottom: 3px solid var(--main-theme-color);
  border-right: 1px solid #ededed;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
}

.table_page table tbody tr td {
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  text-align: center;
  padding: 10px;
}

.table-responsive table tbody tr td {
  border-right: 1px solid #ededed;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  min-width: 150px;
}

.order_table table tbody tr td {
  border-left: 2px solid #ededed;
}

.product_stock h6 {
  font-size: 16px;
}

.table_page table tbody tr td.product_remove {
  min-width: 100px;
  font-size: 20px;
}

.table_page table tbody tr td.product_thumb {
  max-width: 180px;
  overflow: hidden;
}

.table_page table tbody tr td.product_thumb img {
  width: 92px;
  height: 92px;
  object-fit: cover;
}

.table_page table tbody tr td.product_name {
  min-width: 180px;
}

.table_page table tbody tr td.product-price {
  min-width: 130px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.table_page table tbody tr td.product_quantity {
  min-width: 180px;
}
#ReadMoreBtn {
  font-size: 15px;
  cursor: pointer;
  color: #0081b4;
  transition: 0.6s;
  width: max-content;
}
#ReadMoreBtn:hover {
  transition: 0.6s;
  color: #7286d3;
  text-decoration: underline;
  width: max-content;
}
.AddTransition {
  transition: 0.7s;
}
.AnimateOnChange {
  transition: 0.7s;
  transform: rotate(180deg);
}
.table_page table tbody tr td.product_quantity label {
  font-weight: 600;
  margin-right: 5px;
}

.table_page table tbody tr td.product_quantity i {
  margin-left: 10px;
  border-radius: 20px;
  padding: 2px;
  font-size: 20px;
  color: rgb(226, 226, 226);
}

.table_page table tbody tr td.product_quantity input {
  width: 60px;
  height: 40px;
  padding: 0 5px 0 10px;
  background: none;
  border: 1px solid #ededed;
}

.table_page table tbody tr td.product_addcart {
  min-width: 180px;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

.coupon_code {
  border: 1px solid #ededed;
  margin-top: 60px;
}

.coupon_code h3,
.coupon_code .h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: var(--main-theme-color);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.coupon_inner {
  padding: 10px 20px 25px;
}

.coupon_inner p {
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner input {
  border: 1px solid #ededed;
  min-height: 38px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #333;
  width: 175px;
}

.coupon_inner a {
  display: block;
  text-align: right;
  /* font-size: 14px; */
  font-weight: 500;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #ededed; */
  padding-bottom: 10px;
  border-radius: 3px;
}

.cart_subtotal {
  display: flex;
  justify-content: space-between;
}

.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p span {
  margin-right: 30px;
}

.cart_subtotal .cart_amount {
  font-size: 18px;
  font-weight: 500;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  display: initial;
  /* font-size: 12px; */
}

#cart_area_two .plus-minus-input {
  justify-content: center;
}

#cart_area_two .table_page table tbody tr td.product_quantity input {
  margin: 0 8px;
}

.coupon_inner_two {
  padding-top: 30px;
}

.coupon_inner_two p {
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner_two input {
  border: 1px solid #ededed;
  min-height: 38px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #333;
  width: 175px;
}

.coupon_inner_two a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  border-radius: 3px;
}

.product_quantity input {
  text-align: center;
}

.pro-remove button {
  background: transparent;
}

/* ---------------------------------
    Empty Cart Page 
-------------------------------------*/

.empaty_cart_area {
  text-align: center;
}

.empaty_cart_area img {
  width: 100%;
}

.empaty_cart_area h3 {
  padding-top: 15px;
}

.empaty_cart_area a {
  margin-top: 25px;
}

/* ---------------------------------
       CheckOut Page 
-------------------------------------*/

#checkout_one .form-group label {
  font-size: 16px;
  font-weight: 600;
}

.bg-white {
  background-color: #fff;
}

.checkout-area-bg {
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #00000012;
}

.check-heading {
  border-bottom: 2px solid var(--main-theme-color);
}

.check-heading h3 {
  font-weight: 700;
  padding-bottom: 10px;
}

.check-out-form {
  padding-top: 30px;
}

#checkout-area .form-group {
  margin-bottom: 20px;
}

#checkout-area .form-group label {
  font-size: 16px;
  font-weight: 600;
}

#checkout-area .form-control {
  border: 1px solid var(--main-theme-color);
  background: #fff;
  font-size: 14px;
  border-radius: 0;
}

.order_review {
  background-color: #f7f8fb;
  padding: 30px;
}

.order_table thead th {
  border-bottom-width: 1px;
  font-weight: 600;
  color: #fff;
}

.order_table tbody td {
  border: 0;
}

.order_table tfoot th {
  font-weight: 600;
}

.product-subtotal {
  font-weight: 600;
}

.payment_method {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.payment_option .custome-radio {
  margin-bottom: 10px;
}

.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}

.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}

.custome-radio input[type="radio"]:checked + .form-check-label::before {
  border-color: var(--main-theme-color);
}

.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-radio input[type="radio"]:checked + .form-check-label::after {
  opacity: 1;
}

.custome-radio input[type="radio"] + .form-check-label::after {
  content: "";
  background-color: var(--main-theme-color);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}

.payment-text {
  display: none;
  margin-top: 5px;
  margin-bottom: 0;
}

.payment_option .custome-radio:first-child .payment-text {
  display: block;
}

.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.order_review {
  margin-bottom: 40px;
  box-shadow: 0px 0px 10px 0px #00000012;
}

.payment_method {
  padding-top: 30px;
}

.order_table {
  padding-top: 30px;
}

.order_review .table-responsive table thead {
  background: var(--main-theme-color);
  text-align: center;
}

/* ---------------------------------
       CheckOut Two Page 
-------------------------------------*/

.user-actions {
  margin-bottom: 20px;
}

.user-actions h3,
.user-actions .h3 {
  font-size: 13px;
  font-weight: 400;
  background-color: #f7f6f7;
  padding: 15px 10px;
  border-top: 3px solid var(--main-theme-color);
  margin-bottom: 0;
}

.user-actions h3 a,
.user-actions .h3 a {
  color: var(--main-theme-color);
}

.checkout_info {
  border: 1px solid #ededed;
  margin-top: 25px;
  padding: 20px 30px;
}

.checkout_info p {
  margin-bottom: 15px;
  font-size: 14px;
}

.form_group {
  margin-bottom: 20px;
}

.form_group .btn {
  margin-right: 20px;
}

.form_group.group_3 {
  display: flex;
  align-items: center;
}

.checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  height: 45px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

.checkout_coupon .btn {
  margin-left: 20px;
}

.country_option {
  width: 100%;
}

.country_option .list {
  height: 150px;
  overflow-y: scroll;
}

.order-notes {
  margin-bottom: 53px;
}

.default-form-box label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
}

#checkout_two .checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  min-height: 35px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

#checkout_two .checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  max-height: 36px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

.default-form-box .checkbox-default {
  padding-left: 20px;
  margin: 0;
}

#checkout_two .checkout_area_two h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  background: var(--main-theme-color);
  font-weight: 500;
}

#checkout_two #checkout_login label span {
  padding-left: 5px;
}

.checkout_form_area form {
  padding-top: 20px;
}

.checkout_form_area form .default-form-box {
  padding-bottom: 20px;
}

.order_table table thead tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.input-radio span {
  font-weight: 500;
  padding-right: 10px;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.checkbox-default-more-text em {
  margin-top: 16px;
  display: block;
}

#methodCod p {
  padding-bottom: 20px;
}

.checkout_area_two .table-responsive table thead {
  background: #000;
}

/* ---------------------------------
     Order Tracking 
-------------------------------------*/

.order_tracking_wrapper {
  box-shadow: 0px 0px 7px 3px #0000002b;
  padding: 20px 30px;
}

.order_tracking_wrapper p {
  text-align: center;
}

.order_tracking_wrapper h4 {
  padding-bottom: 10px;
  text-align: center;
}

.order_tracking_wrapper form {
  padding-top: 20px;
}

.order_track_button {
  text-align: center;
}

/* ---------------------------------
     Error Page 
-------------------------------------*/

.erorr_wrapper {
  text-align: center;
}

.erorr_wrapper h1 {
  font-weight: 200;
  font-size: 200px;
  text-shadow: 2px 8px 5px #41889d47;
  font-style: italic;
  line-height: 165px;
}

.erorr_wrapper h3 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.erorr_wrapper form input {
  border-radius: 0px;
}

.erorr_wrapper form button {
  background: #000;
  color: #fff;
  padding: 1px 20px;
}

.erorr_wrapper a {
  margin-top: 30px;
}

/* ---------------------------------
     Compare Page 
-------------------------------------*/

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 305px;
  vertical-align: bottom;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category:hover,
.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #b19361;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #333;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  transition: 0.3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.pro-stock {
  font-size: 15px;
  font-weight: 600;
}

.compare-table
  .table
  tbody
  tr
  td.pro-addtocart
  .add-to-cart
  .pro-remove
  button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table table {
  margin-bottom: 0;
}

/* ---------------------------------
     My Account Page 
-------------------------------------*/

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.dashboard_tab_button {
  margin-bottom: 50px;
}

.dashboard_tab_button li a {
  border: 1px solid #e8e8e8;
  border-bottom: none;
  color: #212121;
  font-weight: 500;
  font-size: 15px;
  display: block;
  padding: 18px 15px;
  text-transform: uppercase;
}

.dashboard_tab_button li a:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.dashboard_tab_button li a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.dashboard_tab_button li a.active,
.dashboard_tab_button li a:hover {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  color: #fff;
}

.add_prod_button {
  position: absolute;
  right: 0;
  top: 18px;
}

.dashboard_content h4,
.dashboard_content .h4 {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #212121;
  border-bottom: 1px dashed #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 500;
}

.profils_details_vendor h4 {
  border-bottom: none;
}

.profile_right h4 {
  font-weight: 600 !important;
}

.dashboard_content p a {
  color: #b19361;
  font-weight: 500;
}

.billing_address h5 {
  padding-bottom: 10px;
}

.gift_card h5 {
  padding-bottom: 10px;
}
.gift_card p {
  padding-top: 10px;
}

.gift_card a {
  margin-top: 20px;
}
.input-radio span {
  font-weight: 500;
  padding-right: 10px;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.checkbox-default-more-text em {
  margin-top: 16px;
  display: block;
}

.account_details_form .default-form-box {
  margin-bottom: 20px;
}

.account_details_form .input-radio {
  margin-top: 20px;
}

.account_details_form .example {
  font-size: 14px;
  display: block;
}

.myaccount-content .title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #212121;
  border-bottom: 1px dashed #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 500;
}

.myaccount-content {
  border: 1px solid #e8e8e8;
  padding: 30px;
}

.myaccount-content table {
  overflow: hidden;
}

.billing_address p {
  padding-top: 10px;
}

.billing_address a {
  margin-top: 20px;
}

.img_profiles {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--main-theme-color);
  overflow: hidden;
}

.fixed_icon input {
  opacity: 0;
  position: absolute;
}

/* ---------------------------------
   Login Page 
-------------------------------------*/

.account_form {
  box-shadow: 0px 0px 7px 5px #0000000a;
  padding: 30px 40px;
  background: #fff;
}

.account_form h3 {
  text-align: center;
}

.account_form form {
  padding-top: 30px;
}

.account_form form .default-form-box {
  margin-bottom: 30px;
}

.login_submit {
  text-align: center;
}

.remember_area {
  padding-top: 20px;
  padding-bottom: 10px;
}

/* ---------------------------------
   Faqs Page 
-------------------------------------*/

.faqs_area_wrapper div#accordionEx {
  border: 0;
  background: #f6f6f6;
}

/* .faqs_area_wrapper .card_items_area {
    margin-bottom: 20px;
} */

.faqs_area_wrapper .collapse {
  /* background: #f7f7f7; */
  padding: 20px 20px;
  border-bottom: 1px solid #ddd;
}

.faqs_area_wrapper .collapse .card-body {
  padding: 0px;
  color: #7c7c7c;
}

.faqs_area_wrapper .collapse .card-body p {
  padding-bottom: 10px;
}

.faqs_area_wrapper .collapse .card-body p:last-child {
  padding-bottom: 0px;
}

.faqs_area_wrapper .collapse .card-body p strong {
  font-weight: 500;
}

.faqs_area_wrapper .collapse .card-body ul {
  padding-left: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.faqs_area_wrapper .collapse .card-body ul li {
  list-style: disc;
  padding-top: 10px;
}

.faqs_area_wrapper .collapse .card-body ul li:first-child {
  padding-top: 0px;
}

.faqs_area_wrapper .collapse .card-body ul li:last-child {
  padding-bottom: 10px;
}

.faqs_area_wrapper .card-header {
  width: 100%;
  text-align: left;
  font-size: 18px;
  padding: 0;
  border-bottom: 1px solid #ddd;
  border-top: none;
  border-radius: 0;
  background-color: transparent;
}

/* .faqs_area_wrapper .card-header a h5 {
    color: #41889D;
}

.faqs_area_wrapper .card-header a.collapsed h5 {
    color: #000;
} */

.faqs_area_wrapper .card-header a h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

/* ---------------------------------
   Privacy Policy Page 
-------------------------------------*/

.privacy-policy_area .privacy_content .page_title .privacy_policy_text {
  padding-top: 2rem;
}

.privacy-policy_area .privacy_content .page_title .privacy_policy_text_arabic {
  padding-top: 2rem;
}

.privacy_policy_text_arabic {
  text-align: right;
}

.privacy_policy_text_arabic p {
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: 300;
}
.privacy_policy_text_arabic h3 {
  margin-bottom: 1rem;
  font-size: 18px;
}

.privacy_policy_text_arabic ul li {
  font-size: 14px;
}

.privacy_policy_text_arabic p {
  text-align: justify;
}
.privacy_policy_text_arabic ul li {
  margin-bottom: 20px;
  line-height: 32px;
  position: relative;
  margin-right: 35px;
  font-weight: 300;
}

.privacy_policy_text_arabic ul li:before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/common/Groupsign.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: -35px;
  position: absolute;
  top: 6px;
}

/* ---------------------------------
   Coming Soon 
-------------------------------------*/

#coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  /* background-image: url("../img/common/coming-soon.jpg"); */
  position: relative;
  display: flex;
  align-items: center;
}

#coming_soon_area::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000054;
}

.coming_soon_content {
  text-align: center;
  z-index: 999;
  position: relative;
}

.coming_soon_title {
  padding-top: 30px;
}

.coming_soon_title h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.coming_soon_title p {
  color: #fff;
  padding-top: 10px;
}

.coming_soon_time {
  padding-top: 40px;
}

.coming_soon_newsletter {
  padding-top: 40px;
}

.coming_soon_newsletter h3 {
  color: #fff;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
}

.coming_soon_newsletter form input {
  min-height: 50px;
}

/* -------------------------
  Blog Page Area Start 
---------------------------*/

.blog_one_item {
  /* overflow: hidden; */
  position: relative;
  margin-bottom: 100px;
}

.blog_grid_tabs {
  background-image: url("../img/common/tabs_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
}

#blog_grid .blog_grid_tabs ul {
  align-items: center;
  justify-content: space-evenly;
}

#blog_grid .blog_grid_tabs ul li a {
  padding: 30px 0;
  display: inline-block;
  margin-left: 0;
  color: #fff;
}

#blog_grid .blog_grid_tabs ul li a.active {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
}

.blog_one_img a img {
  height: 350px;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.leftSideImg {
  margin-top: 7.1rem;
}
/*.blog_one_img {
    overflow: hidden;
}

.blog_one_item a img {
    width: 100%;
    overflow: hidden;
} */

.blog_one_item .blog_text button.share_media {
  background: transparent;
  float: right;
}

.blog_text {
  padding: 15px 15px 30px;
  position: absolute;
  background: #fff;
  top: 75%;
  border-radius: 0px 40px 40px 40px;
  width: 100%;
  box-shadow: 0px 3px 5px #ccc;
  height: auto;
  overflow: hidden;
}

.blog_text .date_area  {
  font-size: 16px;
  color: #41889D;
  font-weight: 600;
}
.blog_text .date_area a {
  font-size: 16px;
  color: #41889D;
  font-weight: 600;
  text-decoration: underline;
}

.blog_text .heading {
  padding-top: 15px;
  line-height: 32px;
}
.CardSetHeight {
  margin: 20px 0;
}
.blog_text .para {
  padding-top: 10px;
  min-height: 80px;
  height: auto;
}

.blog_text .button {
  display: block;
  font-size: 16px;
  color: var(--heading-color);
  font-weight: 600;
  border: 1px solid;
  padding: 6px 28px;
  width: 175px;
  margin-top: 17px;
}

.blog_text .button:hover {
  color: var(--main-theme-color);
}

.blog_text .button i {
  padding-left: 10px;
}

#blog_grid_area_one .blog_one_item {
  margin-bottom: 30px;
}

/* -------------------------
  Blog List Page Area
---------------------------*/

#blog_list_area .blog_list_item {
  margin-bottom: 30px;
}

/* -------------------------
  Blog single Page Area
---------------------------*/

.blog_single_img {
  overflow: hidden;
}

.blog_single_img img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: top;
}
.disabled-contact-button {
  pointer-events: none;
}
.blog_single_widget {
  padding-top: 30px;
}

.blog_single_first_Widget {
  padding-top: 15px;
}

.blog_single_first_Widget h2 {
  font-size: 28px;
  font-weight: 600;
}

.blog_single_first_Widget p {
  padding-top: 15px;
}

.blog_single_first_Widget blockquote {
  background: #eaeaea;
  margin-top: 35px;
  padding: 20px 40px;
  font-size: 20px;
  font-style: italic;
}

.blog_details_center_img {
  padding-top: 20px;
}

.blog_single_secend_widget {
  padding-top: 30px;
}

.blog_single_secend_widget h2 {
  font-size: 28px;
  font-weight: 600;
}

.blog_single_secend_widget p {
  padding-top: 15px;
}

.single_categoris_bottom {
  padding-top: 30px;
}

.single_categoris_bottom ul li {
  display: inline-flex;
}

.single_categoris_bottom ul li a {
  color: #636161;
  border: 1px solid #dcd9d9;
  padding: 7px 10px;
  font-size: 14px;
}

.single_categoris_bottom ul li a:hover {
  color: var(--main-theme-color);
  border: 1px solid var(--main-theme-color);
}

.post_author .author_img {
  float: left;
  padding-right: 15px;
}

.post_author .author_info .author_name a {
  font-size: 18px;
  font-weight: 600;
}

.post_author .author_info p {
  padding-top: 10px;
}

.post_author {
  margin-top: 30px;
}

.related_blogs {
  padding-top: 30px;
  margin-bottom: 100px;
}

.single_comment_area {
  padding-top: 50px;
}

.content_title h3 {
  font-size: 24px;
}

.comment_user img {
  height: auto;
  max-width: 80px;
}

.comment_content {
  padding-left: 20px;
}

.comment_content .meta_data h6 {
  font-size: 16px;
}

.comment-time {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-style: italic;
}

.comment_content p:last-child {
  margin-bottom: 0;
}

.comment_info {
  padding-bottom: 20px;
}

.comment_info .children {
  margin-top: 20px;
  padding-top: 20px;
  margin-left: 60px;
}
.comment_info .childrenArabicCOmment {
  margin-top: 20px;
  padding-top: 20px;
  margin-right: 60px;
}

.comment_list {
  padding-top: 30px;
}

.comment_replay_box {
  padding-top: 20px;
}

.comment_replay_box form {
  padding-top: 20px;
}

/* --Sidebar-- */

.shop-sidebar-wrap h3 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.2px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
}

.shop-sidebar-wrap .englishh3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #3e667b;
}
.shop-sidebar-wrap .arabicH3:before {
  content: "";
  position: absolute;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #3e667b;
}

.left-sidebar button {
  position: absolute;
  width: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  border-radius: 0;
  border-color: #ebebeb;
  background-color: #212121;
  color: #fff;
  padding: 0;
  /* z-index: 99999; */
}
.EngButton{
  right: 0;
}
.arabicButton{
  left: 0
}
.search-widget form input {
  min-height: 50px;
}

.left-sidebar .category-post li {
  margin-bottom: 10px;
  display: block;
}

.left-sidebar .category-post li a {
  line-height: 24px;
  position: relative;
  display: block;
}

.left-sidebar .category-post li a span {
  float: right;
}
.left-sidebar .category-post li a .arabicSpan {
  float: left;
}

.left-sidebar .recent-post-widget .recent-single-post {
  margin: 0 0 30px;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side {
  overflow: hidden;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side img {
  max-width: 95px;
  height: 90px;
  object-fit: cover;
  object-position: center;
  width: 95px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side {
  margin: 0 0 0 18px;
  align-self: center;
  align-items: center;
  line-height: 24px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side h5 {
  font-size: 16px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side span {
  font-size: 14px;
  padding-top: 8px;
  display: block;
}

.shop-sidebar-wrap .sidebar-widget-tag li {
  line-height: 1;
  float: left;
  list-style: none;
}

.shop-sidebar-wrap .sidebar-widget-tag li:first-child a {
  margin-left: 0;
}

.shop-sidebar-wrap .sidebar-widget-tag li a {
  display: block;
  float: left;
  padding: 0 20px;
  line-height: 33px;
  font-weight: 400;
  border: 1px solid #ebebeb;
  border-radius: 30px;
  background: #fff;
  margin: 4px;
  text-transform: capitalize;
  color: #474747;
}
.clinicalSubParaText {
  padding: 2rem 3.5rem;
}

.shop-sidebar-wrap .sidebar-widget-tag li a:hover {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  color: #fff;
}

.flicker-widget li {
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  overflow: hidden;
}

.flicker-widget img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  object-position: center;
}

.single_center_img {
  overflow: hidden;
}

.single_center_img img {
  width: 100%;
}

.related_blogs .blog_one_item {
  margin-bottom: 30px;
}

.follwos_icons ul li {
  display: inline-flex;
  padding-left: 20px;
}

.follwos_icons ul li:first-child {
  padding-left: 0px;
}

.follwos_icons p {
  padding-top: 20px;
}

/**=====================
    invoice pages start
==========================**/
.invoice-wrapper {
  background-color: white;
  -webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
  box-shadow: #e6e6e6 0px 0px 14px 3px;
}

.invoice-wrapper h2 {
  font-size: 30px;
}

.invoice-wrapper h4 {
  color: #646464;
  font-size: 18px;
  font-weight: 400;
}

.invoice-wrapper .invoice-detail h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 600;
}

.invoice-wrapper .invoice-detail h6 {
  margin-bottom: 0;
  font-size: 16px;
  color: #6d6d6d;
}

.invoice-wrapper .invoice-body {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .invoice-body .table th,
.invoice-wrapper .invoice-body .table td {
  border-top: none;
}

.invoice-wrapper .invoice-body .table {
  font-size: 16px;
}

.invoice-wrapper .invoice-body .table td {
  color: #646464;
}

.invoice-wrapper .invoice-body .table tbody td,
.invoice-wrapper .invoice-body .table tbody th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.invoice-wrapper .invoice-body .table tbody th {
  font-weight: normal;
  color: #646464;
}

.invoice-wrapper .invoice-body .table tbody tr:first-child td,
.invoice-wrapper .invoice-body .table tbody tr:first-child th {
  padding-top: 22px;
}

.invoice-wrapper .invoice-body .table tbody tr:last-child td,
.invoice-wrapper .invoice-body .table tbody tr:last-child th {
  padding-bottom: 22px;
}

.invoice-wrapper .invoice-body .table tfoot {
  border-top: 1px solid #ddd;
}

.invoice-wrapper .invoice-body .table tfoot td {
  padding-top: 15px;
}

.invoice-wrapper .invoice-footer {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .authorise-sign h6 {
  margin-bottom: 0;
  font-size: 18px;
  color: black;
  font-weight: bold;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  line-height: 1;
}

.theme-invoice-1 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  position: relative;
}

.theme-invoice-1 .invoice-header h4 {
  font-size: 16px;
  color: #646464;
  margin-bottom: 5px;
}

.theme-invoice-1 .invoice-header .upper-icon {
  position: absolute;
  width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  top: 0;
  right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--theme-deafult);
}

.theme-invoice-1 .invoice-header .upper-icon img {
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
  margin-top: 50px;
}

.theme-invoice-1 .invoice-header .header-content h2 {
  font-size: 30px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
  color: var(--theme-deafult);
  text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
  margin-bottom: 0;
  margin-left: 4px;
}

.theme-invoice-4 .invoice-header {
  padding: calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.theme-invoice-4 .invoice-header .background-invoice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.theme-invoice-4 .invoice-wrapper .invoice-body {
  padding: 0;
  z-index: 1;
  position: relative;
}

.theme-invoice-4 .top-sec {
  padding: 0 calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .date-detail li h4 {
  margin-bottom: 0;
}

.theme-invoice-4 .date-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.theme-invoice-4 .date-detail li span {
  font-size: 18px;
  text-transform: capitalize;
  color: var(--main-theme-color);
  margin-right: 8px;
}

.theme-invoice-4 .title-sec {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.theme-invoice-4 .title-sec .title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.theme-invoice-4 .table-sec {
  padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead {
  background-color: black;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead th {
  border-bottom: 0 !important;
  padding: 18px;
  color: white;
}

.invoice-wrapper .invoice-body .table thead th {
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 16px;
  border-color: var(--main-theme-color) !important;
  border-bottom: 1px solid;
}

.theme-invoice-4 .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: white;
  color: #646464;
}

.theme-invoice-4 .table-striped > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: #0000000d;
  color: #212529;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody th,
.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody td {
  padding: 18px 18px !important;
  font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer {
  background-color: var(--main-theme-color);
  display: inline-block;
  padding: 10px 10px 10px 10px;
  color: white;
  margin-top: 30px;
  font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer span:first-child {
  margin-right: 20px;
}

.theme-invoice-4 .table-sec .table-footer span:nth-child(2) {
  font-weight: 600;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  position: relative;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer .design-shape {
  position: absolute;
  top: -34px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li i {
  margin-right: 30px;
  color: var(--main-theme-color);
  font-size: 20px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li h4 {
  line-height: 24px;
  font-size: 16px;
}

/* --Invoice Page Two-- */
.theme-invoice-1 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
}

.theme-invoice-1 .invoice-header .upper-icon {
  position: absolute;
  width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  top: 0;
  right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--main-theme-color);
}

.theme-invoice-1 .invoice-header .upper-icon img {
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
  margin-top: 50px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
  color: var(--main-theme-color);
  text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
  margin-bottom: 0;
  margin-left: 4px;
}

.invoice-wrapper .authorise-sign {
  position: absolute;
  bottom: calc(13px + (40 - 13) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
}
.textBullets {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/* -------------------------
  Contact Page Area
---------------------------*/

.contact_info {
  padding-bottom: 50px;
}

.contact_info h3 {
  padding-bottom: 15px;
}

.left_side_contact ul li {
  padding-bottom: 30px;
}

.left_side_contact .address_location .contact_widget {
  display: flex;
}

.address_location i {
  padding-top: 4px;
  font-size: 18px;
}

.left_side_contact .address_location p {
  padding-left: 20px;
  color: #0a0d14;
}

.contact_widget a {
  display: block;
  padding-left: 20px;
  padding-bottom: 10px;
}

.contact_form_one form .form-group {
  margin-bottom: 30px;
}

.contact_form_one h3 {
  padding-bottom: 30px;
}

.map_area {
  margin-top: 50px;
}

.map_area iframe {
  width: 100%;
  border: none;
  height: 450px;
}

.contact_info_wrapper .map_area {
  margin-top: 0px;
}

#login_area .active {
  color: var(--main-theme-color);
}

/* -------------------------
  Product Hover Page 
---------------------------*/
#elce_weekly_deal .product_item_two {
  margin: 30px 15px 0 0;
}

.product_item_two {
  margin-top: 30px;
}

.product_hover_heading {
  text-align: center;
}

/* --Product Hover TWo-- */
.product_item_two .product_item_inner {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.product_item_two.product_item_inner img {
  width: 100%;
}

.product_item_two .product_button {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
  opacity: 0;
  bottom: -50px;
  left: 50%;
  visibility: hidden;
  position: absolute;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  border: 1px solid #eeeeee;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: table;
  text-align: center;
}

.product_item_two .product_button a {
  border-right: 1px solid #eeeeee;
  font-size: 18px;
  padding: 15px 20px;
  display: table-cell;
}

.product_button a:last-child {
  border: none;
}

.product_item_two .product_detail {
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
}

.product_item_two:hover .product_button {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.product_item_two .product_detail .product_title a {
  font-size: 16px;
}

.product_item_two .product_detail .item_price {
  padding-top: 5px;
  font-weight: 600;
}

/* --Product Hover Three-- */
#hot_Product_area .product_box {
  margin-top: 30px;
}

.product_box {
  margin-bottom: 0px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.product_img {
  position: relative;
  overflow: hidden;
}

.product_img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.product_img img {
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product_action_box {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all 0.5s ease-in-out;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pr_action_btn {
  display: table;
  margin: 0 auto;
}

.product_action_box li:nth-child(1) {
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.product_action_box li {
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0;
}

.pr_action_btn li {
  position: relative;
  margin: 0px 5px;
  display: inline-block;
}

.product_box .pr_action_btn li a {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.pr_action_btn li a {
  background-color: #fff;
  font-size: 0;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  width: 37px;
  height: 37px;
  padding: 0;
  display: block;
  text-align: center;
}

.pr_action_btn li a i {
  vertical-align: middle;
  line-height: 37px;
  font-size: 18px;
}

.product_box .product_info {
  position: relative;
}

.product_info {
  padding: 15px;
}

.product .product_title,
.product_box .product_title,
.product_wrap .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  padding-bottom: 6px;
}

.product_price {
  margin-bottom: 5px;
}

.product_rate {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
  color: #f6bc3e;
}

.rating_num {
  font-size: 14px;
  margin-top: 6px;
  vertical-align: middle;
  display: inline-block;
}

.product_box .add-to-cart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  background-color: #fff;
  padding: 10px 15px 15px 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}

.btn-fill-out {
  background-color: transparent;
  border: 1px solid #ff324d;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product:hover .product_img:before,
.product_box:hover .product_img:before {
  opacity: 1;
}

.product_box:hover .add-to-cart {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product:hover .product_action_box li,
.product_box:hover .product_action_box li,
.product_wrap:hover .product_action_box li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.product_price del {
  font-size: 14px;
  margin-right: 5px;
  margin-left: 3px;
}

.product_price .price {
  color: #ff324d;
  font-weight: 600;
}

/*--------------------------------
         LookBook Page
-----------------------------------*/
.lookbook .lookbook-block {
  position: relative;
}

.lookbook .lookbook-block .lookbook-dot {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 29px;
  height: 29px;
  line-height: 29px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 12%;
  background-color: #ffffff;
}

.lookbook .lookbook-block .lookbook-dot:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  zoom: 1;
  background-color: transparent\9;
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.lookbook .lookbook-block .lookbook-dot span {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
  visibility: hidden;
  top: -98px;
  left: 150%;
  position: absolute;
  width: 130px;
  background-color: #ffffff;
  -webkit-box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
  box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid transparent;
  top: 41%;
  left: -8px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
  margin-top: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  padding: 5px;
  text-align: center;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
  font-weight: 700;
  font-size: 16px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 14px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
  text-transform: uppercase;
  color: var(--main-theme-color);
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
  top: 70%;
  left: 27%;
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
  top: 36%;
  left: 35%;
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
  top: 66%;
  left: 55%;
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
  top: 23%;
  left: 38%;
}

.lookbook .lookbook-block .lookbook-dot:hover {
  background-color: var(--main-theme-color);
  -webkit-box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
  box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
  z-index: 10;
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
  transform: scale(1.3);
}

.lookbook .lookbook-block .lookbook-dot:hover span {
  color: #ffffff;
}

.img-width img {
  width: 100%;
}

.padd-bottom {
  padding-bottom: 30px;
}

/*--------------------------------
        Account Info Edit Page
-----------------------------------*/
.account_thumd {
  text-align: center;
  position: relative;
}

.account_thumd img {
  border-radius: 50%;
}

.account_thumd h4 {
  padding-top: 25px;
  padding-bottom: 5px;
}

.account_thumb_img {
  position: relative;
}

.fixed_icon {
  position: absolute;
  background: #ffffff;
  bottom: 6%;
  right: 10%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 18px;
  transform: translate(-4px, -7px);
  box-shadow: 4px 6px 13px 0px #00000030;
}

.account_setting {
  padding-left: 60px;
}

.account_setting_heading {
  padding-bottom: 20px;
}

.account_setting_heading h2 {
  padding-bottom: 5px;
}

#account_info_form .form-control {
  margin-bottom: 20px;
  font-size: 14px;
}

#account_info_form .form-group {
  margin-bottom: 40px;
}

#account_info_form .input-radio {
  padding-bottom: 20px;
}

/*--------------------------------
    Order Completed Page
-----------------------------------*/
.order_complete i {
  color: #ff324d;
  font-size: 80px;
  margin-bottom: 20px;
}

.order_complete_heading {
  padding-bottom: 30px;
}

.order_complete p {
  padding-bottom: 30px;
}

/*--------------------------------
   Vandor Dashboard Page
-----------------------------------*/
.vendor_top_box {
  text-align: center;
  border: 1px solid #41889d;
  padding: 20px 0;
  box-shadow: 3px 5px 7px 1px #00000014;
  transition: 0.4s all ease-in-out;
}

.vendor_top_box:hover {
  box-shadow: none;
}

.vendor_top_box h2 {
  font-weight: 600;
  color: var(--main-theme-color);
}

.vendor_top_box h4 {
  margin-bottom: 0;
  font-weight: 400;
  padding-top: 5px;
}

.mychart_area {
  padding-top: 30px;
}

.pending_table {
  background: #fff;
  box-shadow: 3px 3px 6px 3px #00000008;
  overflow: hidden;
}

.pending_table thead tr th {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.pending_table tbody tr {
  /* border-bottom: 1px solid #eee; */
  margin-bottom: 10px;
}

.pending_table tbody tr td {
  vertical-align: middle;
  text-transform: capitalize;
  text-align: center;
}

.btn_right_table {
  text-align: right;
  margin-top: 30px;
}

/* --Vendor Profile-- */
.profils_details_vendor {
  display: flex;
}

.profils_details_vendor .profile_left {
  width: 150px;
  margin-right: 15px;
}

.profils_details_vendor .profile_left {
  display: flex;
  align-items: center;
}

.vendors_profiles ul li h4 {
  font-size: 16px;
  color: #787878;
  font-weight: 400;
}

.vendors_profiles .btn_left_table {
  padding-top: 10px;
}

/* --Setting Area-- */
.setings_boxed {
  box-shadow: 0px 0px 3px 2px #00000014;
  padding: 20px 20px;
  margin-bottom: 30px;
}

.setings_boxed h3 {
  font-size: 18px;
  padding-bottom: 10px;
}

.setings_boxed form input {
  margin-right: 10px;
}

/*--------------------------------
        Add Product Page
-----------------------------------*/
.add_product_form .fotm-group {
  margin-bottom: 20px;
}

.add_product_form .fotm-group select {
  display: block;
  width: 100%;
}

.add_product_wrapper h3 {
  padding-bottom: 30px;
}

.add_product_form {
  box-shadow: 0px 2px 8px 4px #0000000f;
  background: #fff;
  padding: 30px 25px;
}
/* clinical study start */
.ClinicalHeading h3 {
  font-size: 36px;
  color: #41889d;
}
#about-top .ClinicalHeading p {
  font-size: 18px;
  line-height: 32px;
  color: #686868 !important;
}

#aboutClinicalStudy {
  /* padding: 10rem 1px 6rem 1px;
  display: flex;
  justify-content: center;
  align-items: center; */
}

#aboutClinicalStudy .imageHolderClinical {
  position: relative;
}

#aboutClinicalStudy .imageHolderClinical img {
  width: 679px;
  height: 420px;
}
#aboutClinicalStudy .imageHolderClinical .UnderImg {
  margin-top: -5rem;
}

#aboutClinicalStudy .imageHolderClinical::after {
  position: absolute;
  content: "";
  background-image: url("../img/common/iconcomman.png");
  width: 100%;
  height: 100%;
  bottom: 17.2rem;
  left: 3rem;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
#aboutClinicalStudy .imageHolderClinical::before {
  position: absolute;
  content: "";
  background-image: url("../img/common/iconcomman.png");
  width: 100%;
  height: 100%;
  top: -22rem;
  left: -6rem;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
#aboutClinicalStudy .imageHolderClinical .OverImg {
  width: 420px;
  height: 360px;
  position: absolute;
  left: 25%;
  bottom: -10rem;
}
/* clinical study left images end*/
#aboutClinicalStudy .upperTextClinicalStudies h2,
.upperTextClinicalStudies h4,
.upperTextClinicalStudies p {
  color: white;
  margin: 2rem auto;
}
#aboutClinicalStudy .upperTextClinicalStudies h2 {
  font-size: 36px;
  line-height: 40.32px;
  font-weight: 400;
}
#aboutClinicalStudy .upperTextClinicalStudies h4 {
  font-size: 32px;
  line-height: 40.32px;
  font-weight: 400;
}
#aboutClinicalStudy .upperTextClinicalStudies p {
  font-size: 16px;
  font-weight: 300;
  line-height: 25.2px;
  margin: 1rem 0;
}
.lowerTextClinicalStudies {
  margin: auto;
  margin-top: 3rem;
  margin-left: 7rem;
}
.mml-10 {
  margin-left: -10px;
}
.headingStudiesResult {
  border-right: solid 3px white;
}
#aboutClinicalStudy .headingStudiesResult h4,
#aboutClinicalStudy .headingStudiesEffects h4 {
  color: white;
  font-size: 24px;
  font-weight: 600;
  line-height: 30.24px;
}
/* clinical study end */
/* valueof Ingrediant section */
#ValueOfIngrediantSection {
  padding: 5rem 10px;
}
#ValueOfIngrediantSection .Clinical_content_paras p {
  color: #686868;
  margin: 2rem auto;
  font-size: 18px;
}

.Clinical_content_list h2 {
  font-size: 36px;
  font-weight: 300;
}

.Clinical_content_list h4 {
  font-size: 28px;
  font-weight: 300;
}

.Clinical_content_list h2,
.Clinical_content_list h4 {
  margin: 2rem auto;
  color: #131d3b;
}
/* valueof Ingrediant section end */

.image-input {
  text-align: center;
}

.image-input input {
  display: none;
}

.image-input label {
  display: block;
  color: #fff;
  background: #000;
  padding: 0.3rem 0.6rem;
  font-size: 17px;
  cursor: pointer;
  width: 26%;
  margin: 21px auto 33px auto;
}

.image-input label i {
  font-size: 125%;
  margin-right: 0.3rem;
}

.image-input label:hover i {
  animation: shake 0.35s;
}

.image-input img {
  max-width: 200px;
  max-height: 200px;
  border: 2px solid #ffc107;
  margin: 0 auto;
}

.back_to_area a {
  font-size: 14px;
  color: var(--main-theme-color);
}

.back_to_area {
  padding-bottom: 40px;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/*--------------------------------
            Preloader
-----------------------------------*/
#preloader {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999999;
}

#status {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

/*--------------------------------
            Offer Modal 
-----------------------------------*/
.newsleetre_modal.modal-body .modal-bg {
  background-color: #f5f5f5;
  padding: 0px;
}

.newsleetre_modal.modal-body {
  padding: 0px;
}

.newsleetre_modal .modal-body .close {
  padding-right: 10px;
  padding-top: 13px;
  position: absolute;
  right: 25px;
  top: 0;
  font-size: 30px;
  cursor: pointer;
  z-index: 999;
}

/*--------------------------------
        Cookies area
-----------------------------------*/
.cookie-bar.show {
  bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cookie-bar p {
  color: #fff;
}

.cookie-bar a {
  margin-left: 20px;
}

.cookie-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  /* bottom: -70px; */
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: var(--main-theme-color);
  padding: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 99;
  border-top: 1px solid #fef5ef96;
}

/* ---Email Templates-- */
.tables_area {
  text-align: center;
  margin: 0 auto;
  /* width: 650px; */
  width: 850px;
  display: block;
}
.arabicLayoutIconShift {
  transform: rotate(180deg);
}
.box_table {
  box-shadow: 0px 0px 11px 5px #0000000f;
}

.tables_area ul {
  margin: 0;
  padding: 0;
}

.tables_area li {
  display: inline-block;
  text-decoration: unset;
}

.tables_area a {
  text-decoration: none;
}

.tables_area p {
  margin: 15px 0;
}

.tables_area h5 {
  color: #444;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
}

.tables_area .text-center {
  text-align: center;
}

.tables_area .main-bg-light {
  background-color: #fafafa;
}

.tables_area .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.tables_area table.top-0 {
  margin-top: 0;
}

.tables_area table.order-detail,
.tables_area .order-detail th,
.tables_area .order-detail td {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.tables_area .order-detail th {
  font-size: 16px;
  padding: 15px;
  text-align: center;
}

.tables_area .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ---Email Template One--- */
.email_template_one {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Open Sans", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_template_one ul {
  margin: 0;
  padding: 0;
}

.email_template_one li {
  display: inline-block;
  text-decoration: unset;
}

.email_template_one a {
  text-decoration: none;
}

.email_template_one h5 {
  margin: 10px;
  color: #777;
}

.email_template_one .text-center {
  text-align: center;
}

.email_template_one .main-bg-light {
  background-color: #fafafa;
}

.email_template_one .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: capitalize;
  display: inline-block;
  line-height: 1;
}

.email_template_one .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_template_one .main-logo {
  width: 180px;
  padding: 10px 20px;
}

.email_template_one .product-box .product {
  /*border:1px solid #ddd;*/
  text-align: center;
  position: relative;
  margin: 0 15px;
}

.email_template_one .product-info {
  margin-top: 15px;
}

.email_template_one .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_template_one .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_template_one .add-with-banner > td {
  padding: 0 15px;
}

.email_template_one .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ---Email Template Two---- */
.email_two_area {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Lato", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_two_area ul {
  margin: 0;
  padding: 0;
}

.email_two_area li {
  display: inline-block;
  text-decoration: unset;
}

.email_two_area a {
  text-decoration: none;
}

.email_two_area h5 {
  margin: 10px;
  color: #777;
}

.email_two_area .text-center {
  text-align: center;
}

.email_two_area .main-bg-light {
  background-color: #fafafa;
}

.email_two_area .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.email_two_area .menu {
  width: 100%;
}

.email_two_area .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_two_area .main-logo {
  width: 180px;
  padding: 10px 20px;
  margin-bottom: -5px;
}

.email_two_area .product-box .product {
  text-align: center;
  position: relative;
}

.email_two_area .product-info {
  margin-top: 15px;
}

.email_two_area .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_two_area .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_two_area .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ----Email Template Three---- */
.email_area_three {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Lato", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_area_three ul {
  margin: 0;
  padding: 0;
}

.email_area_three li {
  display: inline-block;
  text-decoration: unset;
}

.email_area_three a {
  text-decoration: none;
}

.email_area_three h5 {
  margin: 10px;
  color: #777;
}

.email_area_three .text-center {
  text-align: center;
}

.email_area_three .main-bg-light {
  background-color: #fafafa;
}

.email_area_three .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.email_area_three .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_area_three .main-logo {
  width: 180px;
  padding: 10px 20px;
  margin-bottom: -5px;
}

.email_area_three .product-box .product {
  text-align: center;
  position: relative;
}

.email_area_three .product-info {
  margin-top: 15px;
}

/* giftCardSection */

.Select_design {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.select_Design_img {
  width: 5rem;
  cursor: pointer;
  border-radius: 15px;
}
.DesignSelect {
  outline: solid 1px #b56b1f;
  padding: 5px;
}

.email_area_three .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_area_three .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_area_three .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

.back_btn {
  margin-bottom: 60px;
}

.back_btn a {
  font-size: 16px;
  color: var(--main-theme-color);
  font-weight: 500;
}

.back_btn a i {
  margin-right: 10px;
}

.contact_info_wrapper {
  background: #fff;
  box-shadow: 0 0 6px 5px rgb(0 50 85 / 6%);
  padding: 30px 25px;
}

.back_btn_emial {
  padding-top: 36px;
  /* padding-bottom: 30px; */
  /* background: #fff; */
  text-align: left;
}
.back_btn_emialArabic {
  padding-top: 36px;
  text-align: right;
}
.side_card ul li {
  background: #f7f7f7;
  margin-bottom: 30px;
  padding: 24px 20px;
  box-shadow: -3px 0px 0px 0px;
}

#rating-star-furniture {
  display: inline-block;
  padding: 0px 10px 0 0px !important;
}

.after_login {
  padding-left: 26px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.after_login img {
  width: 22px;
  margin-right: 6px;
  height: 22px;
  border-radius: 50%;
  background: #41889d;
}

.after_login i {
  padding-left: 6px;
}

.custom_dropdown {
  display: block;
  height: auto;
  z-index: 999999;
  position: absolute;
  padding: 14px 17px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  top: 33px;
  text-align: left;
  right: 0;
  width: max-content;
}

.after_login:hover .custom_dropdown {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.custom_dropdown li {
  padding-left: 0;
  padding-top: 7px;
}

.custom_dropdown li:first-child {
  padding-left: 0;
  padding-top: 0;
}

.heading_payment label {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
}

.payment_body {
  padding: 6px 0px 9px 23px;
}

.payment_body img {
  cursor: pointer;
}

.payment_area_wrappers {
  margin-bottom: 10px;
}

.btn-main-search {
  color: #fff;
  background: var(--main-theme-color);
}

.go-top {
  position: fixed;
  cursor: pointer;
  right: 25px;
  color: #ffffff;
  background-color: var(--main-theme-color);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  transition: 0.9s;
  border-radius: 50%;
  top: 88%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top i {
  position: absolute;
  top: 43%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 50%;
}

.go-top:hover,
.go-top:focus {
  color: #ffffff;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #41889d;
}

#related_product .product_wrappers_one {
  margin-top: 0;
}

#vendor_area table {
  border: 1px solid #e9ecef;
}


.banner_last_img {
  padding-bottom: 25px;
}

.slider_button_style .owl-nav {
  top: -115px;
  position: absolute;
  right: 0;
}

.discount_item a img {
  width: 100%;
}

.discount_item {
  padding-bottom: 20px;
}

.sp_product_item {
  background: #f2f4f7;
  border-radius: 6px;
  text-align: center;
  border: 4px solid #fff;
}

.sp_product_thumb {
  position: relative;
  padding: 32px 15px 0px;
}

.sp_product_thumb .batch {
  position: absolute;
  top: 25px;
  right: 15px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background: var(--main-theme-color);
  color: #fff;
  padding: 6px 6px;
  min-width: 45px;
  text-align: center;
  z-index: 1;
  line-height: 1;
}

.sp_product_thumb a {
  position: relative;
  display: block;
}

.sp_product_thumb img {
  max-width: 100%;
}

.sp_product_content {
  padding: 25px 40px 25px;
}

.sp_product_content .rating_sp {
  color: var(--main-theme-color);
  font-size: 10px;
  letter-spacing: -0.5px;
  margin-bottom: 4px;
}

.sp_product_content h6 {
  font-size: 16px;
  font-weight: 700;
  color: #515457;
  line-height: 1.4;
  margin-bottom: 7px;
}

.sp_product_content .product_status {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: var(--main-theme-color);
}

.sp_product_content .sp_cart_wrap {
  width: 132px;
  margin: 15px auto 15px;
}

.sp_product_content .sp_cart_wrap .cart_plus_minus {
  position: relative;
}

.sp_product_content .sp_cart_wrap input {
  width: 100%;
  background: #ebebeb;
  border: none;
  padding: 10px 50px;
  font-size: 14px;
  font-weight: 700;
  color: #41464c;
  text-align: center;
  border-radius: 50px;
}

.best_sellers_products .sp_product_item .cart_plus-minus .qtybutton {
  background: #f2f4f7;
  border-color: #fff;
}

.sp_product_content .cart_plus_minus .qtybutton.dec {
  font-size: 20px;
}

.sp_product_content .cart_plus_minus .qtybutton.inc {
  left: auto;
  right: 0;
}

.sp_product_content .cart_plus_minus .qtybutton {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #ebebeb;
  cursor: pointer;
  z-index: 1;
  font-weight: 800;
  font-size: 16px;
  font-family: var(--font-family-heading);
  color: var(--color-secondary);
  user-select: none;
}

.sp_product_content > p {
  margin-bottom: 0;
  font-size: 14px;
  color: #515457;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}


#todays_deails_area .sp_product_thumb img {
  display: inline-block;
  max-width: 100%;
  width: 100px;
}

#todays_deails_area .sp_product_content {
  padding: 20px 20px 23px;
}

#todays_deails_area .sp_product_content h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

#todays_deails_area .sp_product_content .cart_plus_minus .qtybutton {
  width: 35px;
  height: 35px;
}

#todays_deails_area .sp_product_content .sp_cart_wrap input {
  padding: 5px 50px;
  min-height: 35px;
}

#todays_deails_area .sp_product_item {
  margin-bottom: 25px;
}

.btn_cta {
  border: none;
  background: var(--main-theme-color);
  width: 56px;
  height: 44px;
  line-height: 42px;
  font-size: 19px;
  color: #fff;
  border-radius: 0 6px 6px 0;
}

.view_all_arae {
  position: absolute;
  top: 20px;
  right: 0;
}

.view_all_arae a {
  color: var(--main-theme-color);
  font-size: 18px;
  font-weight: 500;
}

.view_all_arae a:hover {
  color: var(--heading-color);
}

.tabs_left_button .nav-tabs {
  border-bottom: none;
  width: 100%;
  justify-content: end;
  position: absolute;
  right: 15px;
  top: -70px;
}

.tabs_left_button .nav-tabs li:first-child a {
  margin-left: 0px;
}

.tabs_left_button .nav-tabs li .active {
  color: var(--main-theme-color) !important;
}

.tabs_left_button .nav-tabs li a {
  margin-left: 50px;
  transition: inherit;
  background: transparent;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.cardImg {
  border-radius: 0 0 5px 5px;
}
.product_homepage {
  background-color: #fff;
}







.blogimgBottum img {
  width: 100%;
  height: 21.15rem;
}
.blogImgtopRight1 {
  max-height: 60vh;
  width: 100%;
  object-fit: cover;
}

/* ----------------------------
     Cart 3 Page
-------------------------------*/
#cart_area_three .coupon_code {
  margin-top: 0;
}

#cart_area_three .cart_submit {
  padding: 20px 0 0 0;
}

#total_cart_form_three .rigth_cart {
  display: inline-block;
  float: right;
}

.total_cart_inner {
  padding: 20px 20px;
}

.total_cart_inner h5 {
  padding-bottom: 20px;
  font-weight: 700;
}

.estimate_for_country {
  font-weight: 800;
  padding-top: 10px;
}

.total_catr_three_bottom {
  margin-top: 30px;
  border-top: 1px solid #e7e7e7;
  padding-top: 12px;
}

.total_catr_three_bottom h5 {
  font-weight: 500;
}

.total_catr_three_bottom h5 span {
  float: right;
}

.bottom_code {
  margin-top: 30px !important;
}

/* ----------------------------
    Product Details 3 Page
-------------------------------*/
#product_single_three .list_three_pro li {
  padding-bottom: 10px;
}

#product_single_three .list_three_pro li span {
  padding-right: 10px;
}

#product_single_three .list_three_pro {
  padding-top: 23px;
}

#product_single_three .customs_selects {
  padding-top: 30px;
}

#product_single_three .variable-single-item {
  padding-top: 10px;
}

#product_single_three .reviews_rating {
  padding-top: 15px;
}

#product_single_three .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_three .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_three .modal_product_content_one p {
  padding-top: 20px;
}

#product_single_three .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_three .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_three .links_Product_areas .theme-btn-one {
  margin-top: 20px;
}

.align_text_color {
  font-weight: 600;
  text-transform: capitalize;
  color: #24262b;
  position: relative;
  top: -9px;
  padding-right: 15px;
}

.product_details_cat {
  padding-top: 20px;
}

.product_details_cat ul li {
  display: inline-flex;
  font-weight: 600;
  text-transform: capitalize;
  color: #24262b;
  position: relative;
  top: -9px;
  padding-right: 8px;
}

.product_details_cat ul li a {
  font-weight: 400;
}

.product_details_bor {
  border-bottom: 1px solid #efefef;
}

.area_custom_details {
  display: flex;
  align-items: center;
}

.product_detail_socials {
  padding-top: 15px;
}

.product_detail_socials ul li {
  padding-left: 10px;
}

.product_detail_socials ul li a {
  display: inline-block;
  height: 36px;
  width: 36px;
  background: #ddd;
  text-align: center;
}

.product_detail_socials ul li a i {
  font-size: 18px;
  line-height: 36px;
  color: var(--heading-color);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.product_detail_socials ul li:hover a {
  background-color: var(--main-theme-color);
}

.product_detail_socials ul li:hover a i {
  color: #fff;
}

.product_detail_socials h6 {
  font-size: 16px;
  font-weight: 700;
}

.primary_default_btn {
  background: #41889d;
  border-radius: 10px;
  color: #fff !important;
  border: 1px solid #41889d;
}

.primary_default_btn:hover {
  color: #41889d !important;
  background: #fff;
}

.secondary_default_btn {
  color: #41889d !important;
  border: 1px solid #41889d;
}

.secondary_default_btn:hover {
  color: #fff !important;
  background: #41889d;
}

.lower_footer_link ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.lower_footer_link ul li {
  color: #fff;
  font-weight: 300;
}

.lower_footer_link ul li a {
  color: #fff;
}

.lower_footer_link ul li a:hover {
  color: #41889d;
}

#newsletter_section {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

#giftdelivery_section {
  background: #f3f3f3;
}

#giftdelivery_section h6 {
  font-weight: 700;
}

.footer_left_side_icon ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.footer_left_side_icon ul li a i {
  font-size: 25px;
  font-weight: 700;
}

.footer_social_div,
.footer_newsletter_div {
  padding-left: 60px;
  padding-right: 60px;
}

#WhyUs_area .image-gallery-slides {
  text-align: left;
  float: left;
}

#WhyUs_area .image-gallery-thumbnails {
  width: 100%;
  /* padding-left: 30px; */
  padding-top: 20px;
}

#WhyUs_area .image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

/* .to_big_why_content_cat_name {
  text-align: -webkit-right;
} */

.rightSideText {
  margin: 0 auto;
}

.to_big_why_content_cat_name h2 {
  font-family: "Ogg-Light";
  /* text-align: center; */
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  /* line-height: 120%; */
  /* color: #B56B1F; */
  background: linear-gradient(
    to bottom,
    #b56b1f,
    #b56b1fd4,
    #b56b1fba,
    #b56b1f96
  );
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  text-transform: uppercase;
  height: max-content;
}

@media (min-width: 578px) and (max-width: 767px) {
  .to_big_why_content_cat_name h2 {
    font-size: 25px;
    height: 100%;
  }
}

section#WhyUs_area .container-fluid {
  position: relative;
}
#WhyUs_area{
  position: relative;
}
/* div#to_big_why_row {
  position: absolute;
  top: 20%;
  right: 2%;
} */

div#my-accordion {
  /* padding-top: 42%; */
  clear: both;
}
.to_big_why_content {
  /* position: relative; */
  background: #fff;
  padding: 40px 30px;
  /* margin-right: -300px;
  width: 100%; */
  margin-right: 1.5rem;
}

#to_big_why_row {
  position: relative;
  z-index: 2;
  margin-top: -33rem;
  width: 60%;
  float: right;
  margin-right: 1.5rem;
  margin-bottom: 60px;
}

.to_big_why_content h2 {
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  color: #41889d;
  font-family: "Ogg-Light";
  margin-bottom: 30px;
}

.to_big_why_content p,
.privacy_policy_text p {
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: 300;
}
.privacy_policy_text h3 {
  margin-bottom: 1rem;
  font-size: 18px;
}

.privacy_policy_text ul li {
  font-size: 14px;
}

.privacy_policy_text p {
  text-align: justify;
}
.packagingAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}
.to_big_why_content ul li,
.about_top_left_content ul li,
.privacy_policy_text ul li {
  margin-bottom: 20px;
  line-height: 32px;
  position: relative;
  margin-left: 35px;
  font-weight: 300;
  /* list-style-image: url('../img/common/Groupsign.png'); */
}

.to_big_why_content ul li:before,
.about_top_left_content ul li:before,
.privacy_policy_text ul li:before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/common/Groupsign.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -35px;
  position: absolute;
  top: 6px;
}
/* arabic layout marign */
.about_top_left_content ul li {
  margin-bottom: 20px;
  line-height: 32px;
  position: relative;
  margin-right: 35px;
}
.about_top_left_content ul li:before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("../img/common/Groupsign.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: -35px;
  position: absolute;
  top: 6px;
}
#Ingredient_area {
  margin: auto;
}

#Ingredient_area .ingredient_title h2 {
  font-family: "Ogg-Light";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  color: #efc589;
  text-transform: capitalize;
}

#Ingredient_area .ingredient_btn {
  text-align: center;
}

#Ingredient_area .ingredient_btn a {
  color: #41889d;
  border-radius: 0;
  padding: 10px 30px;
}
.mettEXpert {
  background-color: #fff;
}
#Ingredient_area .ingredient_btn_readMore {
  color: #41889d;
  border-radius: 0;
  padding: 10px 30px;
  background: transparent;
}
#Ingredient_area .ingredient_btn_readMore:hover {
  background: transparent;
  text-decoration: underline;
}
#Ingredient_area .ingredient_btn a:hover {
  background: #41889d;
  color: #fff;
}

#Ingredient_area .row {
  align-items: center;
}

#Ingredient_area .ingredient_box {
  text-align: center;
  padding: 30px;
}

#Ingredient_area .ingredient_box .ingredient_btn {
  margin-top: 10px;
  display: block;
  text-decoration: underline;
  visibility: hidden;
}

#Ingredient_area .ingredient_hover2:hover {
  background-color: #d1e6ce;
}

#Ingredient_area .ingredient_hover4:hover,
#Ingredient_area .ingredient_hover12:hover {
  background-color: #87cbc2;
}

#Ingredient_area .ingredient_hover5:hover {
  background-color: #5babb6;
}

#Ingredient_area .ingredient_hover7:hover {
  background-color: #3e667b;
}

#Ingredient_area .ingredient_hover10:hover {
  background-color: #384757;
}

#Ingredient_area .ingredient_hover4:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover5:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover7:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover10:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover12:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover4:hover .ingredient_box h3,
#Ingredient_area .ingredient_hover5:hover .ingredient_box h3,
#Ingredient_area .ingredient_hover7:hover .ingredient_box h3,
#Ingredient_area .ingredient_hover10:hover .ingredient_box h3,
#Ingredient_area .ingredient_hover12:hover .ingredient_box h3,
#Ingredient_area .ingredient_hover4:hover .ingredient_box p,
#Ingredient_area .ingredient_hover5:hover .ingredient_box p,
#Ingredient_area .ingredient_hover7:hover .ingredient_box p,
#Ingredient_area .ingredient_hover10:hover .ingredient_box p,
#Ingredient_area .ingredient_hover12:hover .ingredient_box p {
  color: #fff;
}

#Ingredient_area .ingredient_hover2 {
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
}

#Ingredient_area .ingredient_hover2:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover4:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover5:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover7:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover10:hover .ingredient_box .ingredient_btn,
#Ingredient_area .ingredient_hover12:hover .ingredient_box .ingredient_btn {
  visibility: visible;
}

#Ingredient_area .ingredient_box h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

#Ingredient_area .row .col-lg-3.col-sm-6.col-xs-12 {
  padding: 0;
}

#Ingredient_area .ingredient_hover1 .ingredient_onhover {
  display: none;
}

#Ingredient_area .ingredient_hover1:hover .ingredient_onhover {
  display: block;
}
.border_text_card {
  outline: solid 1px #fafafa;
}
#ingredient_list .ingredient_img {
  height: 300px;
  /* min-width: 350px; */
}

#ingredient_list .ingredient_infoCard {
  height: 300px;
  /* min-width: 350px; */
}

#Ingredient_area .ingredient_hover1:hover .ingredient_firstimg {
  display: none;
}

div#ingredient_list {
  margin-top: -6rem;
  margin-bottom: -4rem;
}

.ingredient_hover3,
.ingredient_hover6,
.ingredient_hover9 {
  position: relative;
}

.ingredient_hover3 .ingredient_hover_box,
.ingredient_hover6 .ingredient_hover_box,
.ingredient_hover9 .ingredient_hover_box {
  display: none;
}

.ingredient_hover3:hover .ingredient_hover_box,
.ingredient_hover6:hover .ingredient_hover_box,
.ingredient_hover9:hover .ingredient_hover_box {
  position: absolute;
  z-index: 999;
  top: 5%;
  height: 90%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 35% 5%;
  margin: 0 5%;
  background: #fff;
  display: block;
}

.col-pad-right-none {
  padding-right: 0px;
}

section#MeetInspiration_area {
  background-repeat: no-repeat;
  background-size: 80% 62%;
  background-position: right bottom;
  position: relative;
  padding: 60px 0 30px 0;
}
/* section#MeetInspiration_area {
  content: "";
  background: linear-gradient(30.63deg, #7ebfc0 26.17%, #6cafb5 97.06%);
  margin-top: 50px;
} */
section#MeetInspiration_area::after {
  position: absolute;
  content: "";
  /* background-image: url("../img/common/adobespark.png"); */
  background-image: url("https://esme.b-cdn.net/images/adobespark-168015849442.png");
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: bottom right;
}

#MeetInspiration_area .MeetInspiration_content {
  background-color: #d2eada;
  padding: 35px 70px 35px 70px;
}
#MeetInspiration_area .ispirionarabic {
  border: solid 5px wheat;
}
.MeetInspiration_content h2 {
  color: #3a586a;
  font-family: "Ogg-Light";
  font-style: normal;
  font-weight: 300;
  margin-bottom: 20px;
}
.Innter_items_carasoal {
  width: 600px;
  margin: auto;
}
.MeetInspiration_content p {
  color: #3a586a;
  margin-bottom: 20px;
}

.MeetInspiration_content span {
  background-color: #3a586a;
  color: #fff;
  padding: 10px;
  display: inline-block;
  margin-bottom: 20px;
}
.txtheadding {
  position: relative;
}
.txtheadding::after {
  position: absolute;
}
.MeetInspiration_content a,
.MeetInspiration_content .txtheadding {
  z-index: 99;
}

#book_session_area {
  padding-top: 50px;
}
/* #Join-royalty-program-area {
  padding-top: 50px;
} */
.book_session_btn {
  /* text-align: center; */
  width: 100%;
}

.book_session_btn a {
  padding: 10px 30px;
}
.royalty_program_btn {
  width: 100%;
}
.royalty_program_btn a {
  padding: 10px 30px;
}
#bloglist_area {
  padding: 60px 0;
}
.paymentIcon {
  height: 1.2rem;
  margin: auto;
}
.hide {
  display: none;
}
.show {
  display: block;
}
#bloglist_area .pad-remov .blog_list_tab .blogTabs {
  display: flex;
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 2rem;
  justify-content: space-evenly;
  align-items: center;
}

#bloglist_area .pad-remov .blog_list_tab .blogTabs li {
  margin-right: 1rem;
  cursor: pointer;
}

#bloglist_area .pad-remov .blog_list_tab .blogTabs li:last-child {
  margin-right: 0;
}

#bloglist_area .pad-remov .blog_list_tab .blogTabs li.active {
  color: #b56b1f;
  border-bottom: 1px solid #b56b1f;
}

#medialist_area {
  padding: 60px 0;
  height: max-content;
}

.MediaCartHeight {
  height: 35rem;
}
#bloglist_area .blog_title h2,
#medialist_area .media_title h2 {
  margin-bottom: 20px;
  font-weight: 300;
}

#bloglist_area .blog_title h4,
#medialist_area .media_title h4 {
  margin-bottom: 20px;
  color: #3a586a;
  font-family: "Ogg-Light";
  font-style: normal;
  font-weight: 300;
}
#my-accordion {
  position: relative;
}
#my-accordion .panels .panel .row .content {
  position: absolute;
  right: 20%;
  margin: 0 0 0 auto;
}

#my-accordion .panels .panel .row {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

#my-accordion .panels .panel .row .content h2 {
  color: #fff;
  margin-bottom: 30px;
}
.AccorDionImage {
  object-fit: cover;
}

.accordionMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 20rem;
  width: 100%;
  color: #ffff;
}
#my-accordion .LinkContainerMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
#my-accordion .panels .panel .row .content h4 {
  color: #fff;
  margin-bottom: 30px;
}

#my-accordion .panels .panel .row .content p {
  color: #fff;
  margin-bottom: 30px;
  line-height: 30px;
}

#my-accordion .panels .panel .row .content a,
.accordionStyleBUttonMobile {
  border: 1px solid #fff;
  padding: 10px;
  color: #fff;
}

#my-accordion .panels .panel .row .content a:last-child {
  margin-left: 30px;
}

#medialist_area .tabs_center_button {
  margin: 40px 0;
}

#medialist_area .tabs_center_button ul {
  justify-content: inherit;
}

#medialist_area .tabs_center_button ul li a.active,
#medialist_area .tabs_center_button ul li a:hover {
  background: #3e667b;
  border-radius: 31px;
  color: #fff !important;
}

#medialist_area .tabs_center_button ul li a {
  padding: 15px 30px;
}
#medialist_area .arabiclyou-mg {
  margin-right: 50px;
  margin-bottom: 10px;
}

.btn_media_viewmore {
  text-align: right;
  margin: 40px 0;
}

.btn_media_viewmore a {
  color: #3e667b;
  border: 1px solid #3e667b;
  border-radius: 31px;
  padding: 15px 30px;
}

.btn_media_viewmore a i {
}

.btn_media_viewmore a:hover {
  background: #3e667b;
  color: #fff !important;
}

.blog_content {
  background: #73b4b6;
  padding: 45px;
}

.blog_content h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 30px;
}

.blog_content p {
  color: #fff;
  margin-bottom: 20px;
}

.blog_content a {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
}

#to_days_area .todays_slider button.slick-arrow {
  z-index: 9999;
}

.page_title {
  padding-bottom: 60px;
}

.page_title h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  background: linear-gradient(
    to left,
    #b56b1fd4,
    #b56b1fba,
    #b56b1f96,
    #b56b1f
  );

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  margin-bottom: 20px;
  text-shadow: rgba(181, 107, 31, 0.5) 0px 0px 1.5px;
}

.skinbeauty_bottom_content {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
  margin-top: -50%;
}

.Collagen_peptides {
  min-height: 35rem;
  margin-top: -110%;
}
.Uniqueness_of_formula {
  min-height: 44rem;
  margin-top: -110%;
}

.skinbeauty_bottom_content h4 {
  font-family: "Ogg-Light";
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 60px;
  position: relative;
  font-weight: 300;
}

.skinbeauty_bottom_content h4:before {
  position: absolute;
  content: "";
  height: 2px;
  left: 0;
  right: 0;
  width: 8.7%;
  background: #fff;
  margin: 0 auto;
  bottom: -2rem;
}

.skinbeauty_bottom_content h2 {
  font-family: "Ogg-Light";
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 300;
  background: #f8f8f8;

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 1px;
}

.skinbeauty_bottom_content h3 {
  font-family: "Ogg-Light";
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 300;
}

.skinbeauty_bottom_content p {
  font-family: "Ogg-Light";
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 18px;
}

section#fullbackgound_common_section {
  margin-bottom: 60px;
}
#immunity_common_section {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0px;
  margin-bottom: 60px;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.25);
}

.immunity_content_list h2 {
  color: #fff;
  margin-bottom: 30px;
}

.immunity_content_list ul {
  padding-left: 20px;
}

.immunity_content_list ul li {
  color: #fff;
  margin-bottom: 30px;
  list-style: disc;
}
#immunity_common_section .immunity_content_list p {
  color: white;
}

.align-justify {
  align-items: center;
}

.hyicyT {
  webkit-text-fill-color: black !important;
}
.hyicyT,
.dgrQwv {
  background-image: linear-gradient(to top, #131d3b, #131d3b) !important;
}

.about_top_left_content p {
  color: #131d3b;
}

.about_top_img {
  position: relative;
}

.about_top_img:after {
  position: absolute;
  content: "";
  background-image: url("../img/common/iconcomman.png");
  width: 224px;
  height: 219px;
  background-repeat: no-repeat;
  top: 0;
  left: -15%;
}

.right-bootom-gif::after {
  left: unset !important;
  top: unset !important;
  bottom: 0;
  right: 10%;
}

.remove-gif::after {
  display: none !important;
}

.usage_box {
  padding: 30px;
  background-color: #fff;
  text-align: center;
  height: 95%;
}
.footer_social_div_text {
  font-size: 14.2px;
}
.usage_box h2 {
  color: #131d3b !important;
  margin: 20px 0 !important;
}

.usage_box p {
  color: #131d3b;
  margin-bottom: 15px;
}

.usage_box img {
  background: #41889d;
  padding: 10px;
  border-radius: 50%;
}

#immunity_common_section .process-title {
  /* margin-bottom: 30px; */
  color: #fff;
  font-weight: 500;
  font-size: 19px;
}

#immunity_common_section .title {
  margin-bottom: 60px;
  color: #fff;
  font-weight: 500;
}

#immunity_common_section h4 {
  margin-bottom: 30px;
  color: #fff;
  font-weight: 300;
}

#immunity_common_section p {
  margin-bottom: 30px;
  color: #000;
}

.AdviceOnTheUsage_gif {
  position: relative;
}

.AdviceOnTheUsage_gif::after {
  position: absolute;
  content: "";
  background-image: url("../img/common/image_78-removebg-preview.png");
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.row.pad-remov .col-xs-12 {
  padding: 0;
  overflow-x: hidden;
}

#bloglist_area > .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

#bloglist_area > .container-fluid .row {
  margin-right: 0px;
  margin-left: 0px;
}
#bloglist_area .pad-remov {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blog_list_first_col {
  width: auto;
}
.blog_list_first_col .blog_title {
  background: #73b4b6;
  padding: 30px;
  width: 100%;
  height: 20.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_list_first_col .blog_title h2 {
  color: #fff;
  font-size: 60px;
  line-height: 80px;
}

.blog_list_box {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 98%;
  margin: auto;
}

.blog_list_box_content h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.lang_dropdown {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.lang_dropdown select {
  position: relative;
  background: transparent;
  border: none;
  color: #fff;
  padding: 10px 0;
}

.lang_dropdown select option {
  color: #000;
}

.meet-creator-left {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 70px 8px rgb(0 0 0 / 8%);
  padding: 100px 60px;
  text-align: right;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
  width: 400px;
  z-index: 1;
}

.meet-creator-left h4 {
  margin-bottom: 30px;
  color: #41889d;
}

.meet-creator-left h2,
.meet-creator-left p {
  margin-bottom: 30px;
}

.submit_bitton_contact_one button {
  background: #41889d;
  border: 1px solid #41889d;
  border-radius: 3px;
  height: 45px;
  margin-left: 10px;
  color: #fff;
}

.submit_bitton_contact_one button:hover {
  background-color: #fff;
  color: #41889d;
}

.ingredients-middle {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
  width: 100%;
}

.ingredients-middle h2 {
  color: #fff;
  margin-bottom: 30px;
  font-weight: 300;
}

.ingredients-middle h4 {
  color: #fff;
  margin-bottom: 30px;
  font-weight: 300;
}

.ingredients-middle p {
  color: #fff;
  margin-bottom: 30px;
}

.faqs_area_wrapper h2 {
  margin-bottom: 60px;
  font-weight: 300;
}

.list_duble {
  display: flex;
  justify-content: space-between;
}

.list_duble ul {
  padding-left: 20px;
  margin-bottom: 30px;
}

.list_duble ul li {
  list-style: disc;
}

.packag_img_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 1rem;
}
.shopThemeButton {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.instargramGallery {
  height: 300px;
  width: 384px;
  object-fit: cover;
}
.about_top_right_content h3 {
  margin-bottom: 30px;
  font-size: 20px;
}

.about_top_right_content p {
  color: #131d3b;
}

.register_program_form {
  background: rgba(65, 136, 157, 0.75);
  border-radius: 12px;
  padding: 67px;
  text-align: center;
}

.register_program_form h2 {
  font-weight: 300;
  font-size: 42px;
  line-height: 120%;
  color: #fff;
  font-family: "Ogg-Light";
  margin-bottom: 30px;
}

.register_program_form p {
  margin-bottom: 30px;
  color: #fff;
}

section#register_program_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.register_program_form form input.form-control {
  background: transparent;
  color: #fff;
  border: none;
  border-bottom: 2px solid #fff;
  border-radius: 8px;
  margin-bottom: 30px;
}

.register_program_form form input.form-control::-webkit-input-placeholder {
  color: #fff;
}

.register_program_form form input.form-control:-moz-placeholder {
  color: #fff;
}

.register_program_form form input.form-control::-moz-placeholder {
  color: #fff;
}

.register_program_form form input.form-control:-ms-input-placeholder {
  color: #fff;
}

.register_program_form button {
  width: 90%;
  background-color: #fff;
  color: #41889d;
  border-radius: 8px;
  line-height: normal;
  margin-top: 30px;
}

.register_program_form button:hover {
  color: #fff;
  background-color: #41889d;
}

#WhyUs_area
  .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
  z-index: 9999;
  
}

li.login-dropdown {
  position: relative;
}

ul.login-dropdown-list {
  transform: perspective(600px) rotateX(-90deg);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: max-content;
  /* padding: 25px 20px; */
  padding: 10px 15px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
  transform-origin: 0 0 0;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  /* right: -3rem; */
  right: 0;
  /* left: 0; */
  z-index: 9999999;
}

.login-dropdown-list li a {
  letter-spacing: normal;
  font-size: 16px;
  padding: 5px 0;
  display: block;
}

li.login-dropdown:hover ul.login-dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

/* arabicdropdown */
ul.dropdownlistArabic {
  transform: perspective(600px) rotateX(-90deg);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: max-content;
  /* padding: 25px 20px; */
  padding: 10px 15px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
  transform-origin: 0 0 0;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  /* right: -3rem; */
  /* right: 0; */
  left: 0;
  z-index: 9999999;
}

.dropdownlistArabic li a {
  letter-spacing: normal;
  font-size: 16px;
  padding: 5px 0;
  display: block;
}

li.login-dropdown:hover ul.dropdownlistArabic {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.empaty_cart_area i.fa.fa-shopping-cart {
  font-size: 15rem;
  margin-bottom: 50px;
  color: #41889d;
}

.todays_slider .slick-prev {
  left: -10px;
}

.todays_slider .slick-next {
  right: -10px;
}

@media (min-width: 578px) and (max-width: 767px) {
  .furniture_slider .slider_cat_name h2 {
    font-size: 25px;
    height: auto;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .furniture_slider .slider_cat_name h2 {
    font-size: 26px;
  }

  .furniture_slider_content h2 {
    font-size: 23px;
  }

  #my-accordion .panels .panel .row .content {
    width: 85%;
    margin: 0 auto;
  }

  #MeetInspiration_area .MeetInspiration_content {
    padding-right: 11rem;
  }

  .blog_content {
    padding: 36px;
  }

  .footer_one_widget {
    text-align: center !important;
  }

  .footer_social_div,
  .footer_newsletter_div {
    padding-left: 0;
    padding-right: 0;
  }

  .to_big_why_content_cat_name {
    display: none;
  }

  .mb-none {
    display: none;
  }

  #my-accordion .panels {
    height: 100% !important;
  }

  #Ingredient_area .ingredient_box {
    padding: 30px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1279px) {
  .image-gallery-slide .image-gallery-image {
    width: 60%;
  }

  .to_big_why_content {
    padding: 20px;
  }

  /* div#my-accordion {
    margin-top: 15rem;
  } */

  /* .to_big_why_content_cat_name h2 {
    font-size: 32px;
  } */

  #Ingredient_area .ingredient_box {
    padding: 10px 15px;
  }

  #Ingredient_area .ingredient_box h3 {
    margin-bottom: 15px;
    font-size: 20px;
  }

  #Ingredient_area .ingredient_box p {
    font-size: 15px;
  }

  .blog_list_first_col .blog_title {
    height: 25.8rem;
  }

  .blog_content {
    padding: 40px;
  }

  .btn_media_viewmore a {
    padding: 15px 20px;
  }

  .blog_one_item {
    margin-bottom: 12rem;
  }
}

@media all and (min-width: 1280px) and (max-width: 1366px) {
  .main-menu nav > ul > li > a {
    padding: 5px 5px !important;
  }

  .image-gallery-slide .image-gallery-image {
    width: 74%;
  }

  .to_big_why_content {
    padding: 30px;
  }

  /* div#my-accordion {
    padding-top: 5rem;
  } */

  /* .to_big_why_content_cat_name h2 {
    font-size: 42px;
  } */

  #Ingredient_area .ingredient_box {
    padding: 16.5px 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .blog_list_first_col .blog_title {
    height: 22.3rem;
  }

  .blog_content {
    padding: 40px;
  }
}

/*-----Loader Css-------*/
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 10px auto;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-----Loader Css-------*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.swal2-container {
  z-index: 999999;
}

/** Tracking Page */

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}
.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}
.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}
.steps .step .step-icon-wrap::before {
  left: 0;
}
.steps .step .step-icon-wrap::after {
  right: 0;
}
.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}
.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}
.steps .step:first-child .step-icon-wrap::before {
  display: none;
}
.steps .step:last-child .step-icon-wrap::after {
  display: none;
}
.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #ee3124;
}
.steps .step.completed .step-icon {
  border-color: #ee3124;
  background-color: #ee3124;
  color: #fff;
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}
.track_order {
  color: #fff !important;
  background: green;
  padding: 5px;
  margin-left: 15px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
.order_placed_note p {
  text-align: center;
  margin: 0 0 30px 0;
  background: #f5f5f5;
  color: #000;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
}

/* search bar */
.searchbar-section .search-content {
  position: absolute;
  z-index: 99;
  top: 100%;
  right: 10%;
  visibility: hidden;
  min-width: 600px;
  padding: 15px;
  transition: all 0.5s ease 0s;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  opacity: 0;
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.searchbar-section .search-content.active {
  visibility: visible;
  transform: rotateX(0deg);
  opacity: 1;
}

@media (max-width: 1199px) {
  .searchbar-section .search-content {
    right: 15%;
  }
  .searchbar-section .search-content form input {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .searchbar-section .search-content {
    min-width: 500px;
  }
}

@media (max-width: 767px) {
  .searchbar-section .search-content {
    min-width: auto;
  }
  .searchbar-section .search-active i {
    color: #fff;
  }
}

.searchbar-section .search-content form {
  position: relative;
}

.searchbar-section .search-content form input {
  width: 100%;
  padding: 5px 72px 5px 15px;
  transition: all 0.4s ease 0s;
  color: #000;
  border: 1px solid #d3d3d3;
  outline: medium none;
  background: transparent;
}
.searchbar-section .search-content .button-search {
  font-size: 20px;
  line-height: 1;
  position: absolute;
  top: 8px;
  right: 0;
  height: fit-content;
  padding: 5px 20px 2px;
  transition: all 0.4s ease 0s;
  text-transform: uppercase;
  color: #000;
  border: none;
  border-color: transparent;
  background: transparent;
}

.searchbar-section .search-content .button-search:hover {
  color: #000;
}

.searchbar-section .search-active {
  background: transparent;
  padding: 0;
  margin: 0;
}

.searchbar-section .search-active i {
  font-size: 21px;
}

.searchRowmbl {
  position: absolute;
  background: white;
  top: 100%;
  z-index: 1;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: auto;
}

#shopProductModel .Model_Description
 p {
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
}
#shopProductModel .Model_Description
 ul li {
  line-height: 1.8;
  margin-bottom: 10px;
  list-style: inside;
}

#shopProductModel .Model_Description_arabic
 ul li p {
  line-height: 1.8;
  margin-bottom: 10px;
}
#shopProductModel .Model_Description_arabic
 ul li p:after {
  content: "  ";
  padding-left: 20px;
}
#shopProductModel .Model_Description_arabic
 ul li p:after {
  content: " ● ";
}

/* lightbox image style */
.isotopeSelector .imgSliderStyle {
  transition: all 0.4s ease;
}

.isotopeSelector .overlay-background {
  transform: scale(0);
  transition: all 0.4s ease;
}

.isotopeSelector .overlay-background .iconStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  color: #41889d;
  font-size: 20px;
  display: none;
  transition: all 0.4s ease;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.isotopeSelector .overlay:hover .imgSliderStyle {
  transform: scale(1.3) rotate(2deg);
  transition: all 0.4s ease;
  cursor: pointer;
}

.isotopeSelector .overlay:hover .overlay-background {
  transform: scale(1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(65, 136, 157, 0.649);
  transition: all 0.4s ease;
}

.isotopeSelector .overlay:hover .overlay-background .iconStyle {
  display: flex;
  transition: all 0.4s ease;
  cursor: pointer;
}

.border-portfolio {
  overflow: hidden;
}
